import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../utils/Axios";
import { refreshAccessToken } from "../../redux/slices/AuthSlice";

const initialState = {
  isLoading: false,
  error: null,
  meeting: {},
  meetings: [],
  meetingEndState: false,
  QuickMeeting: {},
  SpeechToTextData: null,
  TranslatedTextData: null,
  supportedLanguages: [],
  opponent: null,
  meetingData: null,
  users: [],
  languageDropdown: [],
};

// Helper function to handle token refresh
const handleTokenRefresh = async (error, rejectWithValue, originalRequest) => {
  if (error.response?.status === 401) {
    try {
      const newToken = await refreshAccessToken();
      if (newToken) {
        // Retry the original request with the new token
        originalRequest.headers["Authorization"] = `Bearer ${newToken}`;
        const response = await API(originalRequest);
        return response;
      }
    } catch (refreshError) {
      return rejectWithValue(refreshError.response.data);
    }
  }
  return rejectWithValue(error.response.data);
};

export const fetchUserDetails = createAsyncThunk(
  "user/fetchDetails",
  async (userId, { rejectWithValue }) => {
    try {
      const response = await API.get(`/auth/users/${userId}/`);
      return response.data;
    } catch (error) {
      return handleTokenRefresh(error, rejectWithValue, {
        method: "get",
        url: `/auth/users/${userId}/`,
      });
    }
  }
);

// Async thunk for Schedule Meeting
export const Schedule = createAsyncThunk(
  "call/schedule/",
  async (meetingDetails, { rejectWithValue }) => {
    try {
      const response = await API.post(
        `/dashboard/call/schedule/`,
        meetingDetails
      );
      return response.data;
    } catch (error) {
      return handleTokenRefresh(error, rejectWithValue, {
        method: "post",
        url: `/call/schedule/`,
        data: meetingDetails,
      });
    }
  }
);

export const GetUpcomingMeetings = createAsyncThunk(
  "call/upcoming/",
  async (_, { rejectWithValue }) => {
    try {
      const response = await API.get(`/dashboard/call/upcoming/`);
      return response.data;
    } catch (error) {
      return handleTokenRefresh(error, rejectWithValue, {
        method: "get",
        url: `/call/upcoming/`,
      });
    }
  }
);

export const fetchUsers = createAsyncThunk(
  "meeting/fetchUsers",
  async (searchTerm, { rejectWithValue }) => {
    try {
      const response = await API.get("/auth/list-of-users/", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`, // Add Authorization header
        },
        params: {
          search: searchTerm, // Send the search term as query param (assuming API accepts search param)
        },
      });
      return response.data.results; // Return the API response data
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

export const QuickMeeting = createAsyncThunk(
  "call/quick/",
  async (data, { rejectWithValue }) => {
    try {
      const response = await API.post(`/dashboard/call/quick/`, data);
      return response;
    } catch (error) {
      return handleTokenRefresh(error, rejectWithValue, {
        method: "post",
        url: `/call/quick/`,
        data,
      });
    }
  }
);

export const joinMeeting = createAsyncThunk(
  "meeting/joinMeeting",
  async ({ email }, { rejectWithValue }) => {
    try {
      const response = await API.post(`/dashboard/call/guest-login/`, {
        email,
      });

      return response.data;
    } catch (error) {
      console.error("Error joining meeting:", error);
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

export const getUpdateLanguage = createAsyncThunk(
  "meeting/getUpdateLanguage",
  async (
    { email, preferred_language, voice_preference },
    { rejectWithValue }
  ) => {
    try {
      const accessToken = localStorage.getItem("access_token");
      if (!accessToken) {
        throw new Error("Access token is missing.");
      }

      const response = await API.patch(
        "/dashboard/call/update-preferences/",
        { email, preferred_language, voice_preference },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`, // Ensure the token is passed in the headers
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching opponent's preferred languages:", error);
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

export const getOpponent = createAsyncThunk(
  "meeting/getOpponent",
  async ({ email, quick_call_id }, { rejectWithValue }) => {
    try {
      const accessToken = localStorage.getItem("access_token");
      if (!accessToken) {
        throw new Error("Access token is missing.");
      }

      const response = await API.post(
        "/dashboard/call/meeting/preferred-language/",
        { email, quick_call_id },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`, // Ensure the token is passed in the headers
            "Content-Type": "application/json",
          },
        }
      );

      return response.data;
    } catch (error) {
      console.error("Error fetching opponent's preferred languages:", error);
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

export const getGuestOpponent = createAsyncThunk(
  "meeting/getGuestOpponent",
  async ({ email, quick_call_id }, { rejectWithValue }) => {
    try {
      const response = await API.post(
        "/dashboard/call/guest-opponent/preferred-language/",
        { email, quick_call_id }
      );

      return response.data;
    } catch (error) {
      console.error("Error fetching opponent's preferred languages:", error);
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

export const getGuestDetails = createAsyncThunk(
  "meeting/getGuestDetails",
  async (
    { guest_id, name, voice_preference, confirm_language, quick_call_id },
    { rejectWithValue }
  ) => {
    try {
      const response = await API.patch("/dashboard/call/guest-details/", {
        guest_id,
        name,
        voice_preference,
        confirm_language,
        quick_call_id,
      });

      return response.data;
    } catch (error) {
      console.error("Error fetching opponent's preferred languages:", error);
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

export const getSupportedLanguages = createAsyncThunk(
  "meeting/getSupportedLanguages",
  async (_, { rejectWithValue }) => {
    try {
      const response = await API.get(
        "/dashboard/call/get-supported-languages/"
      );
      if (Array.isArray(response.data.languages)) {
        return response.data.languages;
      } else {
        throw new Error("Unexpected API response format");
      }
    } catch (error) {
      console.error("Error fetching languages:", error);
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

export const fetchLanguageDropdown = createAsyncThunk(
  "meeting/fetchLanguageDropdown",
  async ({ meeting_id }, { rejectWithValue }) => {
    try {
      const accessToken = localStorage.getItem("access_token"); // Get token from local storage
      if (!accessToken) {
        throw new Error("Access token is missing.");
      }

      const response = await API.get(`/dashboard/call/language-dropdown/`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
        params: { meeting_id },
      });
     return response.data; // Return the response data
    } catch (error) {
      console.error("Error fetching language dropdown:", error);
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

export const SpeechToText = createAsyncThunk(
  "call/speech-to-text/",
  async (
    { audioContent, meeting_id, meeting_type, guest_email },
    { rejectWithValue }
  ) => {
    try {
      const response = await API.post(`/dashboard/call/speech-to-text/`, {
        audioContent,
        meeting_id,
        meeting_type,
        guest_email,
      });
      return response.data;
    } catch (error) {
      return handleTokenRefresh(error, rejectWithValue, {
        method: "post",
        url: `/dashboard/call/speech-to-text/`,
        data: { audioContent, meeting_id, meeting_type, guest_email },
      });
    }
  }
);

export const TranslateText = createAsyncThunk(
  "call/translate-text/",
  async ({ text, sourceLanguage, targetLanguage }, { rejectWithValue }) => {
    try {
      const response = await API.post(`/dashboard/call/translate-text/`, {
        text,
        sourceLanguage,
        targetLanguage,
      });
      return response.data;
    } catch (error) {
      return handleTokenRefresh(error, rejectWithValue, {
        method: "post",
        url: `/dashboard/call/translate-text/`,
        data: { text, sourceLanguage, targetLanguage },
      });
    }
  }
);
export const TextToSpeech = createAsyncThunk(
  "call/text-to-speech/",
  async ({ text, language }, { rejectWithValue }) => {
    try {
      const response = await API.post(`/dashboard/call/text-to-speech/`, {
        text,
        language,
      });
      return response.data; // Assuming the API returns the audio data or URL
    } catch (error) {
      return handleTokenRefresh(error, rejectWithValue, {
        method: "post",
        url: `/dashboard/call/text-to-speech/`,
        data: { text, language },
      });
    }
  }
);

export const fetchCaptions = createAsyncThunk(
  "meeting/fetchCaptions",
  async ({ quick_call_id, language }, { rejectWithValue }) => {
    try {
      const accessToken = localStorage.getItem("access_token"); // Retrieve token from local storage
      if (!accessToken) {
        throw new Error("Access token is missing.");
      }

      const response = await API.post(
        "/dashboard/call/captions/",
        { quick_call_id, language },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      return response.data; // Return the response data
    } catch (error) {
      console.error("Error fetching captions:", error);
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

export const transcriptDownload = createAsyncThunk(
  "meeting/transcriptDownload",
  async ({ quick_call_id, language }, { rejectWithValue }) => {
    try {
      const accessToken = localStorage.getItem("access_token"); // Retrieve token from local storage
      if (!accessToken) {
        throw new Error("Access token is missing.");
      }

      const response = await API.post(
        "/dashboard/call/download-transcript/",
        { quick_call_id, language },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      return response.data; // Return the response data
    } catch (error) {
      console.error("Error getting Transcript:", error);
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

const meetingSlice = createSlice({
  name: "meeting",
  initialState,
  reducers: {
    addMeetingDetails: (state, action) => {
      state.meeting = action.payload;
    },
    meetingEnd: (state) => {
      state.meetingEndState = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(Schedule.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(Schedule.fulfilled, (state, action) => {
        state.isLoading = false;
        state.meeting = action.payload;
        state.meetings.push(action.payload); // Update meetings state with the new meeting
      })
      .addCase(Schedule.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(GetUpcomingMeetings.pending, (state) => {
        state.isLoading = false;
        state.error = null;
      })
      .addCase(GetUpcomingMeetings.fulfilled, (state, action) => {
        state.isLoading = false;
        state.meetings = action.payload;
      })
      .addCase(GetUpcomingMeetings.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(QuickMeeting.pending, (state) => {
        state.isLoading = false;
        state.error = null;
      })
      .addCase(QuickMeeting.fulfilled, (state, action) => {
        state.isLoading = false;
        state.QuickMeeting = action.payload.data;
      })
      .addCase(QuickMeeting.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(fetchUsers.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.isLoading = false;
        state.users = action.payload;
      })
      .addCase(fetchUsers.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(joinMeeting.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(joinMeeting.fulfilled, (state, action) => {
        state.isLoading = false;
        state.meetingData = action.payload;
      })
      .addCase(joinMeeting.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(getUpdateLanguage.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getUpdateLanguage.fulfilled, (state, action) => {
        state.isLoading = false;
        state.opponent = action.payload;
      })
      .addCase(getUpdateLanguage.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(getOpponent.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getOpponent.fulfilled, (state, action) => {
        state.isLoading = false;
        state.opponent = action.payload;
      })
      .addCase(getOpponent.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
    builder
      .addCase(getGuestOpponent.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getGuestOpponent.fulfilled, (state, action) => {
        state.isLoading = false;
        state.guestOpponent = action.payload;
      })
      .addCase(getGuestOpponent.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(getGuestDetails.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getGuestDetails.fulfilled, (state, action) => {
        state.isLoading = false;
        state.opponent = action.payload;
      })
      .addCase(getGuestDetails.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(getSupportedLanguages.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getSupportedLanguages.fulfilled, (state, action) => {
        state.isLoading = false;
        state.supportedLanguages = action.payload;
      })
      .addCase(getSupportedLanguages.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(fetchLanguageDropdown.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchLanguageDropdown.fulfilled, (state, action) => {
        state.languageDropdown = action.payload;
      })
      .addCase(fetchLanguageDropdown.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(SpeechToText.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(SpeechToText.fulfilled, (state, action) => {
        state.isLoading = false;
        state.SpeechToTextData = action.payload;
      })
      .addCase(SpeechToText.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(TranslateText.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(TranslateText.fulfilled, (state, action) => {
        state.isLoading = false;
        state.translatedText = action.payload; // Save the translated text in the state
      })
      .addCase(TranslateText.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(TextToSpeech.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(TextToSpeech.fulfilled, (state, action) => {
        state.isLoading = false;
        state.speechAudioData = action.payload; // Store the audio data in the state
      })
      .addCase(TextToSpeech.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(fetchUserDetails.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchUserDetails.fulfilled, (state, action) => {
        state.isLoading = false;
        state.user = action.payload;
      })
      .addCase(fetchUserDetails.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(fetchCaptions.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchCaptions.fulfilled, (state, action) => {
        state.isLoading = false;
        state.captionsData = action.payload;
      })
      .addCase(fetchCaptions.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(transcriptDownload.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(transcriptDownload.fulfilled, (state, action) => {
        state.isLoading = false;
        state.captionsData = action.payload;
      })
      .addCase(transcriptDownload.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export const { addMeetingDetails, meetingEnd } = meetingSlice.actions;

export default meetingSlice.reducer;
