import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import {
  Box,
  Avatar,
  IconButton,
  Toolbar,
  Typography,
  Button,
  TextField,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemButton,
  ListItemSecondaryAction,
  styled,
  useTheme,
  Drawer as MuiDrawer,
  AppBar as MuiAppBar,
  ListItemIcon,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import Hidden from "@mui/material/Hidden";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import HomeIcon from "@mui/icons-material/Home";
import NotificationsIcon from "@mui/icons-material/Notifications";
import GroupIcon from "@mui/icons-material/Group";
import SettingsIcon from "@mui/icons-material/Settings";
import EmailIcon from "@mui/icons-material/Email";
import { CustomTypography4 } from "../components/Typography/Typography";
import CalenderIcon from "../assets/icons/logos/calendar_add_on.svg";
import AccountPopover from "./AccpuntPopover";
import { useDispatch, useSelector } from "react-redux";
import { openDrawer } from "../redux/slices/DrawerSlice";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import DownloadIcon from "@mui/icons-material/Download";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import PaymentsIcon from "@mui/icons-material/Payments";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import SupportIcon from "@mui/icons-material/Support";
import DescriptionIcon from "@mui/icons-material/Description";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import {
  navHeader,
  openSideBar,
  closeSideBar,
} from "../redux/slices/ThemeSlice";
import profile from "../assets/images/profile/lady1.png";
import logoImage from "../assets/icons/logos/videologo.png";
import {  WHITE } from "../theme/Palette";
import PictureAsPdfIcon from "../assets/icons/logos/pdf.svg";
import DocIcon from "../assets/icons/logos/Word.svg";

const users = [
  {
    id: 1,
    name: "John Doe",
    role: "Developer",
    avatar: "https://i.pravatar.cc/300",
  },
  {
    id: 2,
    name: "Jane Smith",
    role: "Sales Manager",
    avatar: "https://i.pravatar.cc/301",
  },
];

const chat = [
  {
    id: 1,
    message: "John Doe will make the call",
    sender: "James",
    role: "Developer Team",
    avatar: "https://i.pravatar.cc/302",
  },
  {
    id: 2,
    message: "John Doe shared a file",
    sender: "James",
    role: "Blake",
    avatar: "https://i.pravatar.cc/303",
  },
];

const documents = [
  {
    id: 1,
    name: "John-Doe-report.pdf",
    sharedBy: "Blake",
    avatar: (
      <img
        src={PictureAsPdfIcon}
        alt="PDF"
        style={{ width: "70px", height: "70px" }}
      />
    ),
  },
  {
    id: 2,
    name: "John-Doe-resume.doc",
    sharedBy: "John",
    avatar: (
      <img src={DocIcon} alt="Doc" style={{ width: "70px", height: "70px" }} />
    ),
  },
];

const SearchDropdown = ({ setShowDropdown }) => {
  const navigate = useNavigate();

  const handleViewAllClick = () => {
    setShowDropdown(false);
    navigate("/dashboard/search"); // Navigate to the new route
  };

  return (
    <Box sx={{ position: "relative", width: "100%" }}>
      <List
        sx={{
          position: "absolute",
          top: "15px",
          left: 0,
          width: "500px", // Make sure it matches the search bar width
          backgroundColor: "#222526",
          borderRadius: 2,
          padding: 1,
          zIndex: 10,
          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)",
          maxHeight: "650px", // Limit height for scroll
          overflowY: "auto", // Allow scroll for long lists
        }}
      >
        {/* People Section */}
        <Typography
          variant="subtitle1"
          color="textSecondary"
          sx={{
            fontFamily: "Lato",
            fontSize: "14px",
            fontWeight: "700",
            color: "white",
            paddingLeft: "16px",
          }}
        >
          People
        </Typography>
        {users.map((person) => (
          <ListItem key={person.id}>
            <ListItemAvatar sx={{ borderRadius: "10px" }}>
              <Avatar src={person.avatar} sx={{ borderRadius: "10px" }} />
            </ListItemAvatar>
            <ListItemText
              primary={
                <Typography
                  sx={{
                    fontFamily: "Lato",
                    fontSize: "14px",
                    fontWeight: "700",
                    lineHeight: "21px",
                    textAlign: "left",
                  }}
                >
                  {person.name}
                </Typography>
              }
              secondary={
                <Typography
                  sx={{
                    fontFamily: "Lato",
                    fontSize: "14px",
                    fontWeight: "400",
                    lineHeight: "21px",
                    textAlign: "left",
                    color: "#FFFFFF80",
                  }}
                >
                  {person.role}
                </Typography>
              }
            />
          </ListItem>
        ))}

        {/* Chat Section */}
        <Typography
          variant="subtitle1"
          color="textSecondary"
          sx={{
            fontFamily: "Lato",
            fontSize: "14px",
            fontWeight: "700",
            color: "white",
            paddingLeft: "16px",
          }}
        >
          Chat
        </Typography>
        {chat.map((item) => (
          <ListItem key={item.id}>
            <ListItemAvatar sx={{ borderRadius: "10px" }}>
              <Avatar src={item.avatar} sx={{ borderRadius: "10px" }} />
            </ListItemAvatar>
            <ListItemText
              primary={
                <Typography
                  sx={{
                    fontFamily: "Lato",
                    fontSize: "14px",
                    fontWeight: "700",
                    lineHeight: "21px",
                    textAlign: "left",
                  }}
                >
                  {`${item.sender}: ${item.message}`}
                </Typography>
              }
              secondary={
                <Typography
                  sx={{
                    fontFamily: "Lato",
                    fontSize: "14px",
                    fontWeight: "400",
                    lineHeight: "21px",
                    textAlign: "left",
                    color: "#FFFFFF80",
                  }}
                >
                  {item.role}
                </Typography>
              }
            />
          </ListItem>
        ))}

        {/* Documents Section */}
        <Typography
          variant="subtitle1"
          color="textSecondary"
          sx={{
            fontFamily: "Lato",
            fontSize: "14px",
            fontWeight: "700",
            color: "white",
            paddingLeft: "16px",
          }}
        >
          Documents
        </Typography>
        {documents.map((doc) => (
          <ListItem
            key={doc.id}
            sx={{
              marginBottom: "8px", // Add margin between document items
            }}
          >
            <ListItemAvatar sx={{ borderRadius: "10px" }}>
              <Avatar sx={{ borderRadius: "10px", width: 50, height: 50 }}>
                {doc.avatar}
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={
                <Typography
                  sx={{
                    fontFamily: "Lato",
                    fontSize: "14px",
                    fontWeight: "700",
                    lineHeight: "21px",
                    textAlign: "left",
                  }}
                >
                  {doc.name}
                </Typography>
              }
              secondary={
                <Typography
                  sx={{
                    fontFamily: "Lato",
                    fontSize: "14px",
                    fontWeight: "400",
                    lineHeight: "21px",
                    textAlign: "left",
                    color: "#FFFFFF80",
                  }}
                >
                  {`Shared by ${doc.sharedBy}`}
                </Typography>
              }
            />
            <ListItemSecondaryAction>
              <IconButton
                sx={{
                  border: "1px solid rgba(255, 255, 255, 0.2)",
                  borderRadius: "8px",
                }}
              >
                <DownloadIcon sx={{ fontSize: "18px" }} />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        ))}

        {/* View All Button */}
        <Box display="flex" justifyContent="center" marginTop={2}>
          <Button
            onClick={() => handleViewAllClick()}
            variant="outlined"
            sx={{
              color: "#FFFFFF80",
              width: "100%",
              border: "none",
              textTransform: "capitalize",
              fontSize: "16px",
              fontWeight: "700",
              fontFamily: "Lato",
              marginBottom: "20px",
              "&:hover": {
                color: "#fff",
                boxShadow: "none",
                border: "none",
              },
            }}
          >
            View All
          </Button>
        </Box>
      </List>
    </Box>
  );
};

SearchDropdown.propTypes = {
  searchValue: PropTypes.string.isRequired,
  setIsSearchModalOpen: PropTypes.func.isRequired,
  setShowDropdown: PropTypes.bool.isRequired,
};

const SearchBar = styled(TextField)(({ theme, expanded }) => ({
  background: "#222526",
  borderRadius: theme.shape.borderRadius,
  transition: "width 0.3s ease-in-out",
  width: expanded ? "500px" : "300px", // Adjust width based on state
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "none",
    },
  },
}));

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  background: theme.palette.background.default,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 10px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: 99,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  boxShadow: "none",
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  background: "red",
  borderRight: "1px solid rgba(255, 255, 255, 0.15)",
  opacity: 1,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    background: "red",
    borderRight: "1px solid rgba(255, 255, 255, 0.15)",
    opacity: 1,
    "& .MuiDrawer-paper": {
      ...openedMixin(theme),
      borderRight: "1px solid rgba(255, 255, 255, 0.15)",
      opacity: 1,
      background: theme.palette.background.default, // Also set the background color for the paper component
    },
  }),
  ...(!open && {
    ...closedMixin(theme),
    borderRight: "1px solid rgba(255, 255, 255, 0.15)",
    opacity: 1,
    background: theme.palette.background.default, // Apply the color when the drawer is closed
    "& .MuiDrawer-paper": {
      ...closedMixin(theme),
      borderRight: "1px solid rgba(255, 255, 255, 0.15)",
      opacity: 1,
      background: theme.palette.background.default, // Also set the background color for the paper component
    },
  }),
}));

const HeadingText = styled(Typography)(() => ({
  fontFamily: "Yu Gothic UI",
  fontSize: "27.239px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "54.478px",
  color: "#fff",
}));

const LogoIconButton = styled(IconButton)({
  "& img": {
    width: "auto",
    height: "100%",
  },
});

export default function AdminDashboardLayout({ children }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const sideBar = useSelector((state) => state.theme.sideBar);
  const nav = useSelector((state) => state.theme.header);
  const [open, setOpen] = useState(sideBar);
  const [header, setHeader] = useState(nav);
  const [openPopover, setOpenPopover] = useState(false);
  const [search, setSearch] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const navigate = useNavigate();
  const searchRef = useRef(null);
  const location = useLocation();
  const locationState = location.state || {};

  useEffect(() => {
    console.log("Current Location State:", locationState);
    if (locationState.fromContact) {
      // If navigated from the Contact page
      dispatch(navHeader("Messages"));
      setHeader("Messages");
      console.log("Navigated from Contact Page");
    }

    if (
      location.pathname.includes("/admin/Overview" || "admin/overview")
    ) {
      dispatch(navHeader("Overview"));
      setHeader("Overview");
    }

    if (location.pathname.includes("/admin/Notifications")) {
      dispatch(navHeader("Notifications"));
      setHeader("Notifications");
    }

    if (location.pathname.includes("/admin/Customers")) {
      dispatch(navHeader("Customers"));
      setHeader("Customers");
    }

    if (location.pathname.includes("/admin/Plan")) {
      dispatch(navHeader("Plan"));
      setHeader("Plan");
    }

    if (location.pathname.includes("/admin/Billing")) {
      dispatch(navHeader("Billing"));
      setHeader("Billing");
    }

    if (location.pathname.includes("/admin/Promotion")) {
      dispatch(navHeader("Promotion"));
      setHeader("Promotion");
    }

    if (location.pathname.includes("/admin/Support")) {
      dispatch(navHeader("Support"));
      setHeader("Support");
    }

    if (location.pathname.includes("/admin/Reports")) {
      dispatch(navHeader("Reports"));
      setHeader("Reports");
    }

    if (location.pathname.includes("/admin/User")) {
      dispatch(navHeader("User"));
      setHeader("User");
    }

    if (location.pathname.includes("/admin/Settings")) {
      dispatch(navHeader("Settings"));
      setHeader("Settings");
    }

    if (location.pathname.includes("/admin/search")) {
      dispatch(navHeader("Search Results"));
      setHeader(
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            sx={{
              borderRadius: "8px",
              border: `1px solid ${WHITE[50]}`,
              display: "inline-block",
              cursor: "pointer",
              padding: "4px 8px",
              marginRight: "10px",
              fontSize: "16px",
              fontFamily: "Lato",
              fontWeight: 700,
              color: "#FFFFFF80",
              "&:hover": {
                borderColor: WHITE[50],
                backgroundColor: "rgba(255, 255, 255, 0.1)",
              },
            }}
            onClick={() => navigate(-1)} // Go back to the previous page
          >
            Go Back
          </Typography>
          <Typography
            sx={{
              display: "inline-block",
              fontSize: "20px",
              fontFamily: "Lato",
              fontWeight: 700,
            }}
          >
            Search Results
          </Typography>
        </Box>
      );
    }
  }, [locationState, location.pathname, dispatch]);

  // Close search bar when clicking outside of it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setSearch(false); // Collapse the search bar
        setShowDropdown(false); // Hide dropdown
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleDrawerOpen = () => {
    setOpen(true);
    dispatch(openSideBar());
  };

  const handleDrawerClose = () => {
    setOpen(false);
    dispatch(closeSideBar());
  };

  const handleSearchClick = () => {
    setSearch(!search);
    setShowDropdown(!showDropdown);
  };

  const handleSearchInputChange = (e) => {
    setSearchValue(e.target.value);
  };

  const headingLogos = {
    Overview: HomeIcon,
    Notifications: NotificationsIcon,
    Customers: GroupIcon,
    Plan: LocalOfferIcon,
    Billing: PaymentsIcon,
    Promotion: CardGiftcardIcon,
    Support: SupportIcon,
    Reports: DescriptionIcon,
    User: ManageAccountsIcon,
    Settings: SettingsIcon,
  };
  const handleHeader = (text) => {
    dispatch(navHeader(text));
    setHeader(text);
    navigate(`/admin/${text}`);
    console.log(header);
  };

  const handleOpenPopover = () => {
    setOpenPopover(true);
  };

  const handleOpenDrawer = () => {
    dispatch(openDrawer());
  };

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar
        position="fixed"
        open={open}
        sx={{
          background: theme.palette.background.default,
          borderBottom: "1px solid rgba(255, 255, 255, 0.15)",
          opacity: 1,
        }}
      >
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              // onClick={handleDrawerOpen}
              edge="start"
              sx={{
                marginRight: 2,
                marginLeft: open ? 0 : "60px",
                // ...(!open && { display: "none" }),
                p: 1,
                height: "40px",
                opacity: 0.4,
                borderRadius: "10px",
                border: "1px solid rgba(255, 255, 255, 0.50)",
                // display: "inline-block",
                alignItems: "center",
                justifyContent: "center",
                color: "rgba(255, 255, 255, 0.50)",
                fontSize: "10px",
              }}
              onClick={handleDrawerOpen}
            >
              <MenuIcon />
            </IconButton>
            <CustomTypography4
              sx={{ marginLeft: open ? 0 : "5px", fontSize: "20px", fontWeight:700 }}
            >
              {header}
            </CustomTypography4>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: 3,
                marginRight: "1rem",
              }}
            >
              {nav === "Schedule" && (
                <Button
                  variant="contained"
                  startIcon={
                    <img
                      src={CalenderIcon}
                      style={{ marginLeft: "1px" }} // Adjusted the gap for icon and text
                    />
                  }
                  onClick={handleOpenDrawer}
                  sx={{
                    textTransform: "none",
                    width: {
                      xs: "80px", // For small screens like mobile
                      sm: "90px", // For small tablets
                      md: "99px", // For medium screens and above
                    },
                    height: {
                      xs: "36px", // Adjust height for small screens
                      sm: "38px", // Slightly bigger on small tablets
                      md: "40px", // Default height for medium and large screens
                    },
                    borderRadius: "8px",
                    background: theme.palette.primary.info,
                    color: theme.palette.primary.white,
                    ":hover": {
                      background: "#007CD3",
                      color: theme.palette.primary.white,
                    },
                    fontSize: {
                      xs: "10px", // Smaller text size for small screens
                      sm: "11px",
                      md: "12px", // Larger text size for medium and larger screens
                    },
                  }}
                >
                  Schedule
                </Button>
              )}
              {nav === "Contacts" && (
                <IconButton
                  sx={{
                    borderRadius: "8px",
                    padding: "10px",
                    border: `1px solid ${WHITE[50]}`,
                    height:"40px",
                    width:"40px"
                  }}
                >
                  <GroupAddIcon sx={{ color: `#FFFFFF80`,height:"18px",width:"18px" }} />
                </IconButton>
              )}
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <IconButton
                variant="outlined"
                sx={{
                  color: "#FFFFFF80",
                  borderRadius: "8px",
                  border: `1px solid ${WHITE[50]}`,
                  fontWeight: 400, width:"40px", height:"40px", padding:0,
                  fontSize: "18px",
                  lineHeight: "18px",
                  alignItems: "center",
                  fontFamily: "Lato",
                  whiteSpace: "nowrap", // Prevent text from breaking into multiple lines
                }}
              >
                <EmailIcon sx={{ fontWeight: 400,
                  fontSize: "18px",}}/>
              </IconButton>
              <IconButton
                variant="outlined"
                sx={{
                  color: "#FFFFFF80",
                  borderRadius: "8px",
                  border: `1px solid ${WHITE[50]}`,
                  textTransform: "none",
                  fontWeight: 400,
                  fontSize: "18px",
                  lineHeight: "18px",
                  alignItems: "center",
                  fontFamily: "Lato",
                  whiteSpace: "nowrap", // Prevent text from breaking into multiple lines
                }}
              >
                <NotificationsIcon sx={{ fontWeight: 400,
                  fontSize: "18px",}} />
              </IconButton>
              {/* Search Bar */}
              <Box
                sx={{
                  position: "relative", // This ensures the dropdown is correctly positioned within this box
                }}
              >
                {search ? (
                  <SearchBar
                    variant="outlined"
                    sx={{ backgroundColor: "#222526", borderRadius: "8px" ,fontFamily:"Lato"}}
                    value={searchValue}
                    expanded={search} // Use the expanded state
                    onChange={handleSearchInputChange}
                    InputProps={{
                      endAdornment: (
                        <IconButton onClick={handleSearchClick}>
                          <SearchIcon
                            sx={{ color: theme.palette.text.secondary ,fontWeight: 400,
                              fontSize: "18px",}}
                          />
                        </IconButton>
                      ),
                    }}
                  />
                ) : (
                  <IconButton
                    onClick={handleSearchClick}
                    sx={{
                      borderRadius: "8px",
                      border: `1px solid ${WHITE[50]}`, width:"40px", height:"40px",
                    }}
                  >
                    <SearchIcon
                      sx={{
                        color: theme.palette.text.secondary,
                        height: "18px",
                        width: "18px",
                      }}
                    />
                  </IconButton>
                )}
                {showDropdown && (
                  <SearchDropdown
                    searchValue={searchValue}
                    setShowDropdown={setShowDropdown}
                  />
                )}
              </Box>

              <Avatar
                sx={{ borderRadius: "7px" }}
                variant="rounded"
                src={profile}
                onClick={handleOpenPopover}
              ></Avatar>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      {/* <CallNavbar/> */}
      <AccountPopover
        openPopover={openPopover}
        setOpenPopover={setOpenPopover}
      />
      <Drawer
        variant="permanent"
        open={open}
        sx={{
          borderRight: "1px solid rgba(255, 255, 255, 0.15)",
          opacity: 1,
          background: "var(--1-a-1-c-1-d-primary, #1A1C1D)",
        }}
      >
        <DrawerHeader sx={{ marginBottom: "4rem" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <LogoIconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="open drawer"
              sx={{ ml: 4 }}
              onClick={handleDrawerOpen}
            >
              <img
                src={logoImage}
                alt="Logo"
                style={{
                  height: "41.73px",
                  width: "45.44px",
                  marginLeft: "0rem",
                }}
              />
            </LogoIconButton>
            <Hidden smDown>
              {open && (
                <HeadingText>
                  <Typography variant="h6">Talks & Talks</Typography>
                </HeadingText>
              )}
            </Hidden>
          </Box>
          {open && (
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "rtl" ? (
                <ChevronRightIcon sx={{ color: "#fff" }} />
              ) : (
                <ChevronLeftIcon sx={{ color: "#fff" }} />
              )}
            </IconButton>
          )}
        </DrawerHeader>
        {/* <Divider /> */}
        <List
          sx={{
            paddingY: "10px",
            overflowX: "hidden",
             scrollbarWidth:"none",
            fontFamily: "Lato !important",
          }}
        >
          {[
            "Overview",
            "Notifications",
            "Customers",
            "Plan",
            "Billing",
            "Promotion",
            "Support",
            "Reports",
            "User",
            "Settings",
          ].map((text, index) => {
            const IconComponent = headingLogos[text];
            const isActive = text === header; 
            return (
              <ListItem
                key={index}
                disablePadding
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <ListItemButton
                  sx={{
                    justifyContent: open ? "initial" : "center",
                    px: 1.5,
                    borderRadius: "12px",
                    color: isActive ? "#fff" : "#FFFFFF50",
                    ...(text === header &&
                      {
                        // border: `2px solid ${theme.palette.primary.main}`,
                      }),
                  }}
                  onClick={() => handleHeader(text)}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 2 : "auto",
                      justifyContent: "center",
                      border: "1px solid #FFFFFF50", // Adding border to icons
                      padding: "10px", // For visual space
                      borderRadius: "8px",
                      color: isActive ? "#fff" : "#FFFFFF50", 
                    }}
                  >
                    {IconComponent && <IconComponent />}
                  </ListItemIcon>
                  {open && (
                  <ListItemText
                    primary={text}
                    sx={{
                        ml: 2,
                       color: isActive ? "#fff" : "#FFFFFF50"
                    }}
                  />
                  )}
                </ListItemButton>
                <Typography sx={{ fontSize: "12px",color: isActive ? "#fff" : "#FFFFFF50",  }}>{text}</Typography>
              </ListItem>
            );
          })}
        </List>
      </Drawer>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          minHeight: "100vh",
          background: theme.palette.background.default,
        }}
      >
        <DrawerHeader />
        <Outlet />
        {children}
      </Box>
    </Box>
  );
}

AdminDashboardLayout.propTypes = {
  children: PropTypes.node,
};
