import React, { useState } from "react";
import {
  Toolbar,
  IconButton,
  Box,
  Typography,
  styled,
  useTheme,
  Badge,
  Modal,
  Paper,
} from "@mui/material";
import MuiAppBar from "@mui/material/AppBar";
import img from "../../../assets/icons/call/speechtotext.svg";
import MicIcon from "@mui/icons-material/Mic";
import MicOffIcon from "@mui/icons-material/MicOff";
import VideocamIcon from "@mui/icons-material/Videocam";
import VideocamOffIcon from "@mui/icons-material/VideocamOff";
import ScreenShareIcon from "@mui/icons-material/ScreenShare";
import TranslateIcon from "@mui/icons-material/Translate";
import ChatIcon from "@mui/icons-material/Chat";
import HangupIcon from "@mui/icons-material/CallEnd";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PeopleOutlineRoundedIcon from "@mui/icons-material/PeopleOutlineRounded";
import BackHandIcon from "@mui/icons-material/BackHand";
import SettingsIcon from "@mui/icons-material/Settings";
import ClosedCaptionIcon from "@mui/icons-material/ClosedCaption";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { useDispatch } from "react-redux";
import { meetingEnd } from "../../../redux/slices/MeetingSlice";
import PropTypes from "prop-types";
import MeetingEnd from "../../businessUser/Calls/BusinessMettingEnd";
// import Chat_bot from "../../assets/images/chat_bot/ai.svg";

const StyledIconButton = styled(IconButton)(({ isSelected }) => ({
  backgroundColor: isSelected ? "white" : "var(--222526, #222526)", // Background changes to white if selected
  borderRadius: "8px",
  padding: 10,
  color: isSelected ? "#1976d2" : "white", // Text color changes if selected
  margin: 1,
  "&:hover": { backgroundColor: "white", color: "#1976d2" },
}));

const drawerWidth = 240;
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: 99,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const BusinessCallNavbar = ({
  toggleMute,
  isMuted,
  toggleVideo,
  isVideoOn,
  handleToggleTranscript,
  handleToggleTranslation,
  handleToggleChatBot,
  handleToggleChat,
  handleToggleCaptions,
  handleToggleParticipants,
  isGuest,
  setActivePanel,
}) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalAction, setIsModalAction] = useState(false);

  // States to track modal open/close for Participants, Chat, etc.
  const [isHandRaised, setIsHandRaised] = useState(false);
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [isParticipantsOpen, setIsParticipantsOpen] = useState(false);
  const [isTranslationOpen, setIsTranslationOpen] = useState(false);
  const [isChatBotOpen, setIsChatBotOpen] = useState(false);
  const [isTranscriptOpen, setIsTranscriptOpen] = useState(false);
  const [isCaptionsOpen, setIsCaptionsOpen] = useState(false);

  // Toggle each modal while ensuring mutually exclusive selection
  const toggleChatBot = () => {
    setIsChatBotOpen((prev) => !prev);
    setIsChatOpen(false);
    setIsTranslationOpen(false);
    setIsTranscriptOpen(false);
    setIsCaptionsOpen(false);
    handleToggleChatBot();
  };

  const toggleParticipants = () => {
    setIsParticipantsOpen((prev) => !prev);
    setIsChatOpen(false);
    setIsTranslationOpen(false);
    setIsTranscriptOpen(false);
    setIsCaptionsOpen(false);
    handleToggleParticipants();
    setIsChatBotOpen(false);
  };

  const toggleChat = () => {
    setIsChatOpen((prev) => !prev);
    setIsParticipantsOpen(false);
    setIsTranslationOpen(false);
    setIsTranscriptOpen(false);
    setIsCaptionsOpen(false);
    handleToggleChat();
  };

  const toggleTranslation = () => {
    setIsTranslationOpen((prev) => !prev);
    setIsChatOpen(false);
    setIsParticipantsOpen(false);
    setIsTranscriptOpen(false);
    setIsCaptionsOpen(false);
    setIsChatBotOpen(false);
    handleToggleTranslation();
  };

  const toggleTranscript = () => {
    setIsTranscriptOpen((prev) => !prev);
    setIsChatOpen(false);
    setIsParticipantsOpen(false);
    setIsTranslationOpen(false);
    setIsCaptionsOpen(false);
    setIsChatBotOpen(false);
    handleToggleTranscript();
  };

  const toggleCaptions = () => {
    setIsCaptionsOpen((prev) => !prev);
    setIsChatOpen(false);
    setIsParticipantsOpen(false);
    setIsTranslationOpen(false);
    setIsTranscriptOpen(false);
    setIsChatBotOpen(false);
    handleToggleCaptions();
  };

  const toggleVideoButton = () => {
    toggleVideo(); // Call the prop to toggle video
  };

  const handleAction = () => {
    setIsModalAction(true);
  };

  const handleActionClose = () => {
    setIsModalAction(false);
  };

  const handleMeetingEnd = () => {
    setIsModalOpen(true);
    dispatch(meetingEnd());
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleSelectCallSettings = () => {
    setActivePanel("callSettings");
    handleActionClose(); // Close the modal
  };

  const handleSelectTranslationSettings = () => {
    setActivePanel("translationPreference");
    handleActionClose(); // Close the modal
  };

  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          background: theme.palette.background.default,
          padding: "8px 16px",
          boxShadow: "none",
          overflowX: "hidden",
        }}
      >
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box display="flex" alignItems="center">
            <AccessTimeIcon sx={{ color: "white", marginLeft: 6 }} />
            <Typography variant="body1" sx={{ color: "white", marginLeft: 2 }}>
              03:32
            </Typography>
          </Box>

          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{ flexGrow: 1, gap: 2 }}
          >
            <StyledIconButton
              isSelected={isChatBotOpen}
              onClick={toggleChatBot}
              sx={{
                width: "56px",
                height: "56px",
                backgroundColor: "transparent", // Disables hover background color
                boxShadow: "none", 
                "&:hover": {
                  backgroundColor: "transparent", // Disables hover background color
                  boxShadow: "none", // Disables any hover box-shadow
                },
              }}
            >
              {/* <img
                src={Chat_bot}
                alt="chat"
                style={{ width: "56px", height: "56px" }}
              /> */}
            </StyledIconButton>
            {/* Participants Button */}
            <StyledIconButton
              isSelected={isParticipantsOpen}
              onClick={toggleParticipants}
              sx={{ width: "56px", height: "56px" }}
            >
              <Badge color="success">
                <PeopleOutlineRoundedIcon
                  sx={{ width: "18px", height: "18px" }}
                />
              </Badge>
            </StyledIconButton>

            {/* Hand Raised Button */}
            <StyledIconButton
              color="inherit"
              isSelected={isHandRaised}
              onClick={() => setIsHandRaised((prev) => !prev)}
              sx={{ width: "56px", height: "56px" }}
            >
              <BackHandIcon sx={{ width: "18px", height: "18px", color: "" }} />
            </StyledIconButton>

            {/* Chat Button */}
            <StyledIconButton
              color="inherit"
              isSelected={isChatOpen}
              onClick={toggleChat}
              sx={{ width: "56px", height: "56px" }}
            >
              <ChatIcon sx={{ width: "18px", height: "18px" }} />
            </StyledIconButton>

            {/* Translation Button */}
            <StyledIconButton
              color="inherit"
              isSelected={isTranslationOpen}
              onClick={toggleTranslation}
              sx={{ width: "56px", height: "56px" }}
            >
              <TranslateIcon sx={{ width: "18px", height: "18px" }} />
            </StyledIconButton>

            {/* Transcript Button */}
            <StyledIconButton
              color="inherit"
              isSelected={isTranscriptOpen}
              onClick={toggleTranscript}
              sx={{
                width: "56px",
                height: "56px",
                "&:hover img": {
                  filter:
                    "brightness(0) saturate(100%) invert(32%) sepia(97%) saturate(2473%) hue-rotate(185deg) brightness(102%) contrast(102%)",
                },
                ...(isTranscriptOpen && {
                  "& img": {
                    filter:
                      "brightness(0) saturate(100%) invert(32%) sepia(97%) saturate(2473%) hue-rotate(185deg) brightness(102%) contrast(102%)",
                  },
                }),
              }}
            >
              <img
                src={img}
                alt="icon"
                style={{
                  width: "18px",
                  height: "18px",
                }}
              />
            </StyledIconButton>

            {/* Captions Button */}
            <StyledIconButton
              color="inherit"
              isSelected={isCaptionsOpen}
              onClick={toggleCaptions}
              sx={{ width: "56px", height: "56px" }}
            >
              <ClosedCaptionIcon
                sx={{ width: "18px", height: "18px", color: "" }}
              />
            </StyledIconButton>

            {/* Mute Button */}
            <StyledIconButton
              color="inherit"
              isSelected={isMuted}
              onClick={toggleMute}
              sx={{ width: "56px", height: "56px" }}
            >
              {isMuted ? (
                <MicOffIcon sx={{ width: "18px", height: "18px" }} />
              ) : (
                <MicIcon sx={{ width: "18px", height: "18px" }} />
              )}
            </StyledIconButton>

            {/* Hangup Button */}
            <StyledIconButton
              sx={{
                background: "rgba(235, 87, 87, 1)",
                height: "56px",
                width: "56px",
              }}
              onClick={handleMeetingEnd}
            >
              <HangupIcon sx={{ width: "18px", height: "18px" }} />
            </StyledIconButton>

            {/* Video Button */}
            <StyledIconButton
              color="inherit"
              isSelected={!isVideoOn} // Selected when the video is off
              onClick={toggleVideoButton} // Call to toggle video
              sx={{ width: "56px", height: "56px" }}
            >
              {isVideoOn ? (
                <VideocamIcon sx={{ width: "18px", height: "18px" }} />
              ) : (
                <VideocamOffIcon sx={{ width: "18px", height: "18px" }} />
              )}
            </StyledIconButton>

            {/* Screen Share Button */}
            <StyledIconButton
              color="inherit"
              isSelected={isTranscriptOpen === 9}
              onClick={() =>
                setIsTranscriptOpen((prev) => (prev === 9 ? null : 9))
              }
              sx={{ width: "56px", height: "56px" }}
            >
              <ScreenShareIcon sx={{ width: "18px", height: "18px" }} />
            </StyledIconButton>

            {/* More Button */}
            <StyledIconButton
              color="inherit"
              isSelected={isModalAction}
              onClick={handleAction}
              sx={{ width: "56px", height: "56px" }}
            >
              <MoreVertIcon sx={{ width: "18px", height: "18px" }} />
            </StyledIconButton>
          </Box>
        </Toolbar>
      </AppBar>

      {/* More Action Modal */}
      <Modal
        open={isModalAction}
        onClose={handleActionClose}
        aria-labelledby="more-vert-modal"
        aria-describedby="more-vert-description"
      >
        <Paper
          elevation={3}
          sx={{
            position: "absolute",
            top: "80px",
            right: "50px",
            backgroundColor: "#222526",
            color: "white",
            width: "200px",
            borderRadius: "8px",
            padding: "10px 20px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "10px 0",
            }}
            onClick={handleSelectCallSettings}
          >
            <Typography
              sx={{ fontWeight: 600, fontSize: "12px", fontFamily: "Lato" }}
            >
              <SettingsIcon sx={{ paddingRight: "5px" }} /> Call Settings
            </Typography>
            <Typography variant="body2">{">"}</Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "10px 0",
            }}
            onClick={handleSelectTranslationSettings}
          >
            <Typography
              sx={{ fontWeight: 600, fontSize: "12px", fontFamily: "Lato" }}
            >
              <TranslateIcon sx={{ paddingRight: "5px" }} /> Translation
              Settings
            </Typography>
            <Typography variant="body2">{">"}</Typography>
          </Box>
        </Paper>
      </Modal>

      {/* Meeting End Modal */}
      <Modal
        open={isModalOpen}
        onClose={handleCloseModal}
        aria-labelledby="meeting-end-modal"
        aria-describedby="meeting-end-description"
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
<MeetingEnd isGuest={isGuest} handleCloseModal={handleCloseModal} />
        </Box>
      </Modal>
    </>
  );
};

BusinessCallNavbar.propTypes = {
  toggleMute: PropTypes.func.isRequired,
  isMuted: PropTypes.bool.isRequired,
  toggleVideo: PropTypes.func.isRequired,
  isVideoOn: PropTypes.bool.isRequired,
  handleToggleTranscript: PropTypes.func.isRequired,
  handleToggleTranslation: PropTypes.func.isRequired,
  handleToggleChatBot: PropTypes.func.isRequired,
  handleToggleChat: PropTypes.func.isRequired,
  handleToggleCaptions: PropTypes.func.isRequired,
  handleToggleParticipants: PropTypes.func.isRequired,
  isGuest: PropTypes.bool,
  setActivePanel: PropTypes.func.isRequired,
};

export default BusinessCallNavbar;
