import React, { useState, useEffect } from "react";
import OuterNavBar from "../../businessUser/BusinessNavBar/BusinessOuterNavbar";
import {
    Button,
    InputAdornment,
    IconButton,
    InputLabel,
    Stack,
    useTheme,
    Typography,
    CircularProgress,
} from "@mui/material";
import { CenterItemBox } from "../../../components/Box/CustomBox";
import {
    CustomSubtitle,
    CustomTypography1,
    CustomTypography4,
} from "../../../components/Typography/Typography";
import { CustomTextField } from "../../../components/TextField/TextField";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { FormikProvider, Form, useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
//import { useDispatch } from "react-redux";
//import { SetNewPassword } from "../../../redux/slices/AuthSlice";

function BusinessResetPassword() {
    const theme = useTheme();
    const navigate = useNavigate();
    //const dispatch = useDispatch();
    //const location = useLocation();
    // const { uidb64, token } = useParams();
    const [passwordsMatch, setPasswordsMatch] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [apiError, setApiError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    //const searchParams = new URLSearchParams(location.search);
    // const uidb64 = searchParams.get("uidb64");
    //const token = searchParams.get("token");

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            password: "",
            confirmPassword: "",
        },
        validationSchema: Yup.object().shape({
            password: Yup.string()
                .required("Password is required")
                .min(8, "Password must be at least 8 characters"),
            confirmPassword: Yup.string()
                .oneOf([Yup.ref("password"), null], "Passwords must match")
                .required("Confirm Password is required"),
        }),
        onSubmit: async (values, { resetForm }) => {
            setIsLoading(true);
            setApiError(null);
            try {
                // const data = {
                //   password: values.password,
                //   confirm_password: values.confirmPassword,
                //   uidb64: uidb64,
                //   token: token,
                // };
                // await dispatch(SetNewPassword(data)).unwrap();
                console.log("Password reset successful");
                navigate("/businessauth/password-reset-success");
            } catch (error) {
                console.log("Error resetting password", error);
                // setApiError(
                //   error?.password ? error.password[0] : "An error occurred. Please try again."
                // );
                resetForm();
            } finally {
                setIsLoading(false);
            }
        },
    });

    const { errors, values, touched, handleSubmit, handleChange } = formik;

    useEffect(() => {
        if (values.password && values.confirmPassword) {
            setPasswordsMatch(values.password === values.confirmPassword);
        } else {
            setPasswordsMatch(false);
        }
    }, [values.password, values.confirmPassword]);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    return (
        <>
            <OuterNavBar data={"New User?"} />
            <FormikProvider value={formik}>
                <CenterItemBox>
                    <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
                        <Stack mt={10}>
                            <CustomTypography1 sx={{ fontSize: "32px", lineHeight: "42px", fontFamily: "Lato" }}>Reset Your Password</CustomTypography1>
                        </Stack>
                        <Stack mt={1}>
                            <CustomSubtitle sx={{ color: "#FFFFFF80", fontWeight: 400, fontSize: "14px", lineHeight: "20px" }}>Enter secure password and save</CustomSubtitle>
                        </Stack>

                        <Stack mt={10}>
                            <InputLabel
                                htmlFor="password"
                                sx={{
                                    color: "rgba(255, 255, 255, 0.4)",
                                    fontSize: "14px",
                                    fontWeight: 400,
                                    lineHeight: "21px",
                                    marginBottom: "-5px", // Adjust this to reduce the gap between the label and the text field
                                    fontFamily: "Lato",

                                }}
                            >
                                Password
                            </InputLabel>
                            <CustomTextField
                                fullWidth
                                id="password"
                                name="password"
                                type={showPassword ? "text" : "password"}
                                value={values.password}
                                onChange={handleChange}
                                placeholder="Enter new password"
                                InputProps={{
                                    style: {
                                        fontFamily: "Lato",
                                        fontSize: "16px",
                                        fontStyle: "normal",
                                        //fontWeight: 500,
                                        lineHeight: "19.2px",
                                        color: "rgba(255, 255, 255, 0.5)"
                                    },
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={togglePasswordVisibility} edge="end">
                                                {showPassword ? (
                                                    <LockOpenIcon
                                                        sx={{
                                                            color: "var(--ffffff-50, rgba(255, 255, 255, 0.50))",
                                                            fontSize: "18px",
                                                        }}
                                                    />
                                                ) : (
                                                    <LockIcon
                                                        sx={{
                                                            color: "var(--ffffff-50, rgba(255, 255, 255, 0.50))",
                                                            fontSize: "18px",
                                                        }}
                                                    />
                                                )}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                sx={{
                                    width: "100%",
                                    "@media (min-width: 600px)": {
                                        width: "420px",
                                    },

                                }}
                                variant="standard"
                                error={Boolean(touched.password && errors.password)}
                                helperText={touched.password && errors.password}
                            />
                        </Stack>

                        <Stack mt={3}>
                            <InputLabel
                                htmlFor="confirmPassword"
                                sx={{
                                    color: "rgba(255, 255, 255, 0.4)",
                                    fontSize: "14px",
                                    fontWeight: 400,
                                    lineHeight: "21px",
                                    fontFamily: "Lato",

                                    marginBottom: "-5px", // Adjust this to reduce the gap between the label and the text field
                                }}
                            >
                                Confirm Password
                            </InputLabel>
                            <CustomTextField
                                fullWidth
                                id="confirmPassword"
                                name="confirmPassword"
                                // type="password"
                                value={values.confirmPassword}
                                onChange={handleChange}
                                placeholder="Confirm your password "
                                InputProps={{
                                    style: {
                                        fontFamily: "Lato",
                                        fontSize: "16px",
                                        fontStyle: "normal",
                                        //fontWeight: 500,
                                        lineHeight: "19.2px",
                                        color: "rgba(255, 255, 255, 0.5)"
                                    },
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <CheckCircleIcon
                                                sx={{
                                                    color: passwordsMatch
                                                        ? theme.palette.success.main
                                                        : "rgba(255, 255, 255, 0.50)",
                                                    fontSize: "18px",
                                                }}
                                            />
                                        </InputAdornment>
                                    ),
                                }}
                                sx={{
                                    width: "100%",
                                    "@media (min-width: 600px)": {
                                        width: "420px",
                                    },
                                    "& .MuiInput-underline:before": {
                                        borderBottomColor: passwordsMatch
                                            ? theme.palette.success.main
                                            : "rgba(255, 255, 255, 0.50)",
                                    },
                                    "& .MuiInput-underline:after": {
                                        borderBottomColor: passwordsMatch
                                            ? theme.palette.success.main
                                            : theme.palette.error.main,
                                    },
                                }}
                                variant="standard"
                                error={Boolean(touched.confirmPassword && errors.confirmPassword)}
                                helperText={touched.confirmPassword && errors.confirmPassword}
                            />
                        </Stack>

                        {/* Display API Error */}
                        {apiError && (
                            <Typography color="error" mt={2}>
                                {apiError}
                            </Typography>
                        )}

                        {/* Submit Button */}
                        <Stack
                            mt={10}
                            sx={{
                                width: "100%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            <Button
                                sx={{
                                    borderRadius: "10px",
                                    width: "420px",
                                    height: "50px",
                                    background: theme.palette.primary.info,
                                    color: theme.palette.primary.white,
                                    padding: "10px",
                                    textTransform: "none",
                                    "@media (min-width: 600px)": {
                                        width: "420px",
                                    },
                                    "&:hover": {
                                        background: theme.palette.primary.info,
                                        boxShadow: "none",
                                    },
                                }}
                                type="submit"
                                variant="contained"
                                disabled={isLoading}
                            >
                                {isLoading ? (
                                    <CircularProgress size={24} color="inherit" />
                                ) : (
                                    <CustomTypography4 sx={{ fontSize: "16px", fontWeight: 600, color: "rgba(255, 255, 255, 0.9)" }}>
                                        Reset Password
                                    </CustomTypography4>
                                )}
                            </Button>
                        </Stack>
                    </Form>
                </CenterItemBox>
            </FormikProvider>
        </>
    );
}

export default BusinessResetPassword;
