import React, { useState } from "react";
import OuterNavBar from "../BusinessNavBar/BusinessOuterNavbar";
import {
  Button,
  InputAdornment,
  InputLabel,
  Stack,
  useTheme,
  CircularProgress,
  Typography,
} from "@mui/material";
import { CenterItemBox } from "../../../components/Box/CustomBox";
import ForgetLogo from "../../../assets/images/forgetPassword/forgetpasswordlogo.svg";
import {
  CustomSubtitle,
  CustomTypography2,
  CustomTypography4,
} from "../../../components/Typography/Typography";
import { CustomTextField } from "../../../components/TextField/TextField";
import MailIcon from "@mui/icons-material/Mail";
//import { RecoverPassword } from "../../../redux/slices/AuthSlice";
//import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {  PATH_BUSINESSAUTH } from "../../../routes/Path";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";


// Validation Schema
const validationSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email format").required("Email is required"),
});

function BusinessForgetPassword() {
  const theme = useTheme();
  //const dispatch = useDispatch();
  const navigate = useNavigate();

  const [apiError, setApiError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (values) => {
    setIsLoading(true);
    setApiError(null);
    try {
      localStorage.setItem("email", values.email);
      //const res = await dispatch(RecoverPassword({ email: values.email })).unwrap();
      //console.log("Password recovery email sent", res);
      navigate(PATH_BUSINESSAUTH.BusinessemailCheck);
    } catch (error) {
      console.error("Error sending password recovery email", error);
      setApiError(
        error?.email ? error.email[0] : "An error occurred. Please try again."
      );
      setTimeout(() => {
        setApiError("");
      }, 1000);
    } finally {
      setIsLoading(false);
    }
  };

  const handleNavigate = () => {
    navigate(PATH_BUSINESSAUTH.login);
  };

  return (
    <>
      <OuterNavBar data={"New User?"} />
      <CenterItemBox sx={{ paddingBlock: "100px", paddingInline: "50px" }}>
        <Stack
          spacing={2}
          mt={2}
          sx={{
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img width="80%" src={ForgetLogo} alt="Forget Password Logo" />
        </Stack>
        <Stack mt={4}>
          <CustomTypography2>
            Lost your password?
            <br /> Enter your details to recover.
          </CustomTypography2>
        </Stack>
        <Stack mt={2}>
          <CustomSubtitle sx={{ color: "#FFFFFF80" }}>
            Enter your details to proceed further
          </CustomSubtitle>
        </Stack>
        <Formik
          initialValues={{ email: "" }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ values, handleChange, handleBlur, touched, errors }) => (
            <Form>
              <Stack mt={5}>
                <InputLabel htmlFor="email" sx={{ color: "#FFFFFF80",fontFamily:'Lato',fontSize:"14px",lineHeight:"21px",
                    marginBottom: "-7px" // Adjust the spacing between the label and input field
                 }}>
                  Email
                </InputLabel>
                <Field
                  as={CustomTextField}
                  fullWidth
                  id="email"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Start typing ..."
                  InputProps={{
                    style: {
                      fontFamily: "Lato",
                      fontSize: "16px",
                  
                      lineHeight: "19.2px",
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <MailIcon
                          sx={{
                            color: "var(--ffffff-50, rgba(255, 255, 255, 0.50))",
                            fontSize: "18px",
                          }}
                        />
                      </InputAdornment>
                    ),
                  }}
                  sx={{
                    "& .MuiInputBase-input::placeholder": {
                      color: "rgba(255, 255, 255, 1)",
                      opacity: 1,
                    },
                    "& .MuiInput-underline:before": {
                      borderBottomColor:
                        touched.email && errors.email
                          ? "red"
                          : "rgba(245, 245, 250, 0.4)",
                    },
                    "& .MuiInput-underline:after": {
                      borderBottomColor:
                        touched.email && errors.email
                          ? "red"
                          : "rgba(255, 255, 255, 0.50)",
                    },
                    width: "100%",
                    "@media (min-width: 600px)": {
                      width: "420px",
                    },
                  }}
                  variant="standard"
                  error={Boolean(touched.email && errors.email)}
                  helperText={<ErrorMessage name="email" />}
                />
              </Stack>

              {/* Display API Error */}
              {apiError && (
                <Typography color="error" mt={2}>
                  {apiError}
                </Typography>
              )}

              {/* Display Loading Indicator */}
              {isLoading && (
                <Stack mt={2} alignItems="center">
                  <CircularProgress />
                </Stack>
              )}

              <Stack
                mt={4}
                sx={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Button
                  sx={{
                    fontWeight: 700,
                     fontSize: "16px", 
                     lineHeight: "19.2px",
                    textTransform: "none",
                    background: theme.palette.primary.info,
                    borderRadius: "8px",
                    p: 1.5,
                    color: theme.palette.primary.white,
                    "&:hover": {
                      background: theme.palette.primary.info,
                      boxShadow: "none",
                    },
                    width: "100%",
                    "@media (min-width: 600px)": {
                      width: "420px",
                    },
                  }}
                  type="submit"
                  variant="contained"
                  disabled={isLoading}
                >
                  <CustomTypography4
                    sx={{ fontWeight: 700, fontSize: "16px", lineHeight: "19.2px" }}
                  >
                    Recover
                  </CustomTypography4>
                </Button>
              </Stack>
            </Form>
          )}
        </Formik>
        <Stack
          mt={2}
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Button
            sx={{
              textTransform: "none",
              width: "100%",
              backgroundColor: theme.palette.background.dark,
              color: theme.palette.primary.white,
              p: 1.5,
              borderRadius: "8px",
              "@media (min-width: 600px)": {
                width: "420px",
              },
            }}
            onClick={handleNavigate}
            variant="contained"
          >
            <CustomTypography4
              sx={{ fontWeight: 700, fontSize: "15px", lineHeight: "19.2px" }}
            >
              Go Back
            </CustomTypography4>
          </Button>
        </Stack>
      </CenterItemBox>
    </>
  );
}

export default BusinessForgetPassword;
