window.process = window.process || {};
window.process.nextTick = setTimeout;

import "./App.css";
import React, { useMemo } from "react";
import { Provider as ReduxProvider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import Router from "./routes";
import { store, persistor } from "./redux/Store";
import { PersistGate } from "redux-persist/lib/integration/react";
import { useSelector } from "react-redux";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { themeSettings } from "./theme/Palette";

function App() {
  const mode = useSelector((state) => state.theme.mode);  // Select mode from your Redux store

  // Create theme based on the mode (light/dark)
  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);

  return (
    <ReduxProvider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <BrowserRouter>
            <Router />
          </BrowserRouter>
        </ThemeProvider>
      </PersistGate>
    </ReduxProvider>
    
  );
}

export default App;
