import React from "react";
import OuterNavBar from "../../businessUser/BusinessNavBar/BusinessOuterNavbar";
import { Button, Stack,useTheme } from "@mui/material";
import { CenterItemBox } from "../../../components/Box/CustomBox";
import Sucessful from "../../../assets/images/Email/EmailSendImg.svg";
import {
  CustomSubtitle,
  CustomTypography2,
  CustomTypography4,
} from "../../../components/Typography/Typography";
import { useNavigate } from "react-router-dom";

function BusinessForgetPasswordResetSuccess() {
  const navigate = useNavigate();
  const theme = useTheme();

  const handleNavigate =()=>{
    navigate('/businessauth/login')
  }
  return (
    <>
      <OuterNavBar data={"New User?"}/>
        <CenterItemBox >
          <Stack spacing={2}>
            <img width="100%" src={Sucessful} />
          </Stack>
          <Stack mt={4}>
            <CustomTypography2>Password Reset Sucessful</CustomTypography2>
          </Stack>
          <Stack mt={2}>
            <CustomSubtitle>
              Your password has been successfully reset
              <br /> click below to login
            </CustomSubtitle>
          </Stack>

          <Stack
            mt={4}
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button
              sx={{
                width: "201px",
                height: "50px",
                borderRadius: "10px",
                textTransform: "none",
                background:theme.palette.primary.info,
                color:theme.palette.primary.white,
                padding: "12px",
                "@media (min-width: 600px)": {
                  width: "210px",
                },
              }}
              variant="contained" onClick={handleNavigate}
            >
              <CustomTypography4 sx={{fontSize:"16px",fontFamily:"Lato"}}> Login</CustomTypography4>
             
            </Button>
          </Stack>
        </CenterItemBox>
    </>
  );
}

export default BusinessForgetPasswordResetSuccess;
