import React, { useState, useEffect, forwardRef } from "react";
import {
  Box,
  Typography,
  IconButton,
  FormControl,
  Button,
  useTheme,  
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const CallSettings = forwardRef(({setActivePanel}) => {
  const [videoDevices, setVideoDevices] = useState([]);
  const [audioDevices, setAudioDevices] = useState([]);
  const [speakerDevices, setSpeakerDevices] = useState([]);
  const [selectedVideoDevice, setSelectedVideoDevice] = useState(""); //commented for hide
  const [selectedAudioDevice, setSelectedAudioDevice] = useState("");
  const [selectedSpeakerDevice, setSelectedSpeakerDevice] = useState("");
  const theme = useTheme();

  useEffect(() => {
    const getDevices = async () => {
      const devices = await navigator.mediaDevices.enumerateDevices();
      console.log("devices", devices);
      const videoInputDevices = devices.filter(
        (device) => device.kind === "videoinput"
      );
      const audioInputDevices = devices.filter(
        (device) => device.kind === "audioinput"
      );
      const audioOutputDevices = devices.filter(
        (device) => device.kind === "audiooutput"
      );

      setVideoDevices(videoInputDevices);
      setAudioDevices(audioInputDevices);
      setSpeakerDevices(audioOutputDevices);

      if (videoInputDevices.length > 0) {
        setSelectedVideoDevice(videoInputDevices[0].deviceId);
      }
      if (audioInputDevices.length > 0) {
        setSelectedAudioDevice(audioInputDevices[0].deviceId);
      }
      if (audioOutputDevices.length > 0) {
        setSelectedSpeakerDevice(audioOutputDevices[0].deviceId);
      }
    };

    getDevices();
  }, []);
  

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        padding: "16px 16px 0",
        color: "#FFF",
        borderRadius: "8px",
        boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
        display: "flex",
        flexDirection: "column", justifyContent:"space-between", 
      }}
    >
      <Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between", // Places the IconButton on the right side
          width: "100%", // Make the container take full width
        }}
      >
        <Typography
          variant="h5"
          sx={{
            fontWeight: 600,
            fontSize: "18px",
            lineHeight: "27px",
            fontFamily: "Lato",
            color: "rgba(255, 255, 255, 0.9)",
          }}
        >
          Call settings
        </Typography>
        <IconButton
          size="small"
          sx={{
            color: "white",
            border: "1px solid #444",
            borderRadius: "10px",
            width: "36px",
            height: "36px",
          }}
          onClick={() => setActivePanel(null)} 
        >
          <CloseIcon
            sx={{
              height: "18px",
              width: "18px",
              color: "rgba(255, 255, 255, 0.5)",
            }}
          />
        </IconButton>
      </Box>

      <FormControl fullWidth sx={{ mt: 5 }}>
        <InputLabel
          id="videoInput-label"
          sx={{
            color: "rgba(255, 255, 255, 0.5)",
            fontWeight: "400",
            fontFamily: "Lato",
            fontSize: "14px",
            position: "absolute", // Keep the label inside the input area
            // Vertically center the label
            transform: "translateY(-50%)", // Ensure the label is vertically centered
            left: "8px", // Align label with the start of the input
            paddingLeft: "12px", // Match padding with the select content
            pointerEvents: "none", // Prevent interaction with the label
          }}
        >
          Video
        </InputLabel>
        <Select
          labelId="videoInput-label"
          id="videoInput"
          value={selectedVideoDevice}
          label="Select Camera"
          onChange={(e) => setSelectedVideoDevice(e.target.value)}
          variant="standard" // Use standard variant for underline style
          IconComponent={KeyboardArrowDownIcon} // Set the custom Star icon
          sx={{
            color: "", // Ensure the text is white
            marginLeft: "20px",
            fontWeight: 600,
            fontSize: "15px",
            fontFamily: "Lato",

            "& .MuiSelect-select": {
              paddingBottom: "15px", // Adjust padding between text and the underline
            },

            "&:before": {
              borderBottom: "1px solid rgba(245, 245, 250, 0.4)", // Placeholder line (underline)
            },
            "&:after": {
              marginTop: "2rem",
              borderBottom: "2px solid rgba(245, 245, 250, 0.4)", // Thicker line on focus
            },

            "&:hover:not(.Mui-disabled):before": {
              borderBottom: "2px solid rgba(245, 245, 250, 0.4)", // Line on hover
            },
            "& .MuiSvgIcon-root": {
              color: "rgba(255, 255, 255, 0.5)", // White color for dropdown arrow
              width: "18px",
              height: "18px",
              position: "absolute", // Keep the arrow in its original position
              lineHeight: "18px",
            },
          }}
        >
          {videoDevices.map((device) => (
            <MenuItem key={device.deviceId} value={device.deviceId}>
              {device.label || `Camera ${device.deviceId}`}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl fullWidth sx={{ mt: 7 }}>
        <InputLabel
          id="audioInput-label"
          sx={{
            color: "rgba(255, 255, 255, 0.5)",
            fontWeight: "400",
            fontFamily: "Lato",
            fontSize: "14px",
            position: "absolute", // Keep the label inside the input area
            // Vertically center the label
            transform: "translateY(-50%)", // Ensure the label is vertically centered
            left: "8px", // Align label with the start of the input
            paddingLeft: "12px", // Match padding with the select content
            pointerEvents: "none", // Prevent interaction with the label
          }}
        >
          Microphone
        </InputLabel>
        <Select
          labelId="audioInput-label"
          id="audioInput"
          value={selectedAudioDevice}
          label="Select Microphone"
          onChange={(e) => setSelectedAudioDevice(e.target.value)}
          variant="standard" // Use standard variant for underline style
          IconComponent={KeyboardArrowDownIcon} // Set the custom Star icon
          sx={{
            color: "", // Ensure the text is white
            marginLeft: "20px",
            fontWeight: 600,
            fontSize: "15px",
            fontFamily: "Lato",
            "& .MuiSelect-select": {
              paddingBottom: "15px", // Adjust padding between text and the underline
            },

            "&:before": {
              borderBottom: "1px solid rgba(245, 245, 250, 0.4)", // Placeholder line (underline)
            },
            "&:after": {
              marginTop: "2rem",
              borderBottom: "2px solid rgba(245, 245, 250, 0.4)", // Thicker line on focus
            },

            "&:hover:not(.Mui-disabled):before": {
              borderBottom: "2px solid rgba(245, 245, 250, 0.4)", // Line on hover
            },
            "& .MuiSvgIcon-root": {
              color: "rgba(255, 255, 255, 0.5)", // White color for dropdown arrow
              width: "18px",
              height: "18px",
            },
          }}
        >
          {audioDevices.map((device) => (
            <MenuItem key={device.deviceId} value={device.deviceId}>
              {device.label || `Microphone ${device.deviceId}`}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl fullWidth sx={{ mt: 7 }}>
        <InputLabel
          id="audioOutput-label"
          sx={{
            color: "rgba(255, 255, 255, 0.5)",
            fontWeight: "400",
            fontFamily: "Lato",
            fontSize: "14px",
            position: "absolute", // Keep the label inside the input area
            // Vertically center the label
            transform: "translateY(-50%)", // Ensure the label is vertically centered
            left: "8px", // Align label with the start of the input
            paddingLeft: "12px", // Match padding with the select content
            pointerEvents: "none", // Prevent interaction with the label
          }}
        >
          Select Speakers
        </InputLabel>
        <Select
          labelId="audioOutput-label"
          id="audioOutput"
          value={selectedSpeakerDevice}
          label="Select Speakers"
          onChange={(e) => setSelectedSpeakerDevice(e.target.value)}
          variant="standard" // Use standard variant for underline style
          IconComponent={KeyboardArrowDownIcon} // Set the custom Star icon
          sx={{
            color: "", // Ensure the text is white
            marginLeft: "20px",
            fontWeight: 600,
            fontSize: "15px",
            fontFamily: "Lato",
            "& .MuiSelect-select": {
              paddingBottom: "15px", // Adjust padding between text and the underline
            },

            "&:before": {
              borderBottom: "1px solid rgba(245, 245, 250, 0.4)", // Placeholder line (underline)
            },
            "&:after": {
              marginTop: "2rem",
              borderBottom: "2px solid rgba(245, 245, 250, 0.4)", // Thicker line on focus
            },

            "&:hover:not(.Mui-disabled):before": {
              borderBottom: "2px solid rgba(245, 245, 250, 0.4)", // Line on hover
            },
            "& .MuiSvgIcon-root": {
              color: "rgba(255, 255, 255, 0.5)", // White color for dropdown arrow
              width: "18px",
              height: "18px",
            },
          }}
        >
          {speakerDevices.map((device) => (
            <MenuItem key={device.deviceId} value={device.deviceId}>
              {device.label || `Speaker ${device.deviceId}`}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      </Box>
      <Box>

        <Button
          variant="contained"
          sx={{
            textTransform: "none",
            width: "100%",
            borderRadius: "8px",
            marginTop: "10px",
            padding: "8px 40px",
            background: theme.palette.primary.info,
            color: theme.palette.primary.white,
            fontSize: "16px",
            fontFamily: "Lato",
            ":hover": {
              background: "#007CD3",
              color: theme.palette.primary.white,
            },
          }}
        >
          Save
        </Button>
      </Box>
    </Box>
  );
});

CallSettings.displayName = "CallSettings";

CallSettings.propTypes = {
  quickCallId: PropTypes.string.isRequired,
  setActivePanel:PropTypes.string.isRequired
};

export default CallSettings;
