import * as React from "react";
import PropTypes from 'prop-types'
import { styled } from "@mui/material/styles";
import {AppBar,useTheme,Box} from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid"; // Import Grid component
import Hidden from "@mui/material/Hidden"; // Import Hidden component
import MenuIcon from '@mui/icons-material/Menu';
import logoImage from "../../assets/icons/logos/logo.svg";
import { CustomButton } from "../../components/Butons/CustomButton";
import { useNavigate } from "react-router-dom";
import { CustomTypography4 } from "../../components/Typography/Typography";

const HeadingText = styled(Typography)(() => ({
  fontFamily: "Yu Gothic UI",
  fontSize: "27.239px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "54.48px",
}));

const LogoIconButton = styled(IconButton)({
  "& img": {
    width: "auto",
    height: "100%",
  },
});

export default function OuterNavBar({ data }) {
 const navigate = useNavigate()
 const theme = useTheme()
  const hanldeNavigate =()=>{
    if(data === "New User?"){
      navigate('/auth/sign-up')
    }else{
      navigate('/auth/login') 
    }
  }
  return (
    // <Box>
      <AppBar
        sx={{
          background: theme.palette.background.lightGrey,
          borderBottom: "1px solid rgba(255, 255, 255, 0.50)",
          boxShadow:"none"
        }}
      >
        <Toolbar>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <LogoIconButton
                  size="large"
                  edge="start"
                  color="inherit"
                  aria-label="open drawer"
                  sx={{ ml: 4 }}
                >
                  <img src={logoImage} alt="Logo" />
                </LogoIconButton>
                <Hidden smDown>
                  <HeadingText>
                    <Typography variant="h6">Talks &Talks</Typography>
                  </HeadingText>
                </Hidden>
              </Box>
            </Grid>
            <Grid item>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Hidden smDown>
                  <Typography variant="subtitle2" sx={{ marginRight: 2 }}>
                   <CustomTypography4>{data}</CustomTypography4> 
                  </Typography>
                </Hidden>
                <Hidden smDown>
                {data && <CustomButton
                  variant="contained"
                  sx={{ marginRight: 2, p: 3, height: "44px",borderRadius:"10px",background:"rgba(161, 161, 161, 0.1)" ,":hover":{
                     background: " rgba(161, 161, 161, 0.1)",
                  }}}
                  onClick={hanldeNavigate}
                >
                 <CustomTypography4 sx={{fontFamily:"Lato"}}>{data === "New User?" ? "Create Account ?" : (data === null ? null : "Sign In")}</CustomTypography4> 
                </CustomButton>}
                
                </Hidden>
                
                <Hidden smUp>
                  <IconButton
                    size="large"
                    edge="end"
                    color="inherit"
                    aria-label="open drawer"
                  >
                    <MenuIcon />
                  </IconButton>
                </Hidden>
              </Box>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
   
  );
}

OuterNavBar.propTypes ={
  data:PropTypes.string
}
