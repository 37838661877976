import React, { useState ,useEffect} from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  Button,
  Chip,
  TextField,
  Autocomplete,
  InputLabel,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { PRIMARY, WHITE } from "../../theme/Palette";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { useTheme } from "@mui/material/styles";
import { createFilterOptions } from "@mui/material";
import { OutlinedButton, CloseButton } from "../../components/Butons/CustomButton";
import { CustomTypography4 } from "../../components/Typography/Typography";
import { useDispatch, useSelector } from "react-redux";
import { fetchUsers } from "../../redux/slices/MeetingSlice"; 
import BasicModal from "../../components/Modal/CustomModal";
import ContactSucess from "./ContactSucess";

const filter = createFilterOptions();
//For update profile sucess style
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  borderRadius: "8px",
  background: "var(--222526, #222526)",
  boxShadow: "0px 6px 16px 0px rgba(153, 155, 168, 0.10)",
  p: 4,
};

const InviteModal = ({ open, handleClose,  }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);
  const [inputValue, setInputValue] = useState(""); // Track current input value
  const [selectedValues, setSelectedValues] = useState([]);

  const { users } = useSelector((state) => state.meeting);

  useEffect(() => {
    if (inputValue) {
      // Dispatch the fetchUsers thunk when inputValue changes
      dispatch(fetchUsers(inputValue));
    }
  }, [inputValue, dispatch]);


  const handleSelectChange = (event, newValue) => {
    const uniqueValues = [...new Set(newValue)]; // Ensure uniqueness
    setSelectedValues(uniqueValues);
  };

  // Automatically accept manually typed value
  const handleBlur = () => {
    if (inputValue !== "" && !selectedValues.includes(inputValue)) {
      setSelectedValues((prev) => [...prev, inputValue]);
    }
    setInputValue(""); // Clear the input field after accepting the value
  };

   const handleSendInvite = () => {
  //   setInvitees(selectedValues);
  //   onNext();
     handleClose(); // Close the modal after sending invites
   };

  return (

    <>
    <BasicModal open={openModal} setOpen={setOpenModal} style={style}>
    <ContactSucess onClose={setOpenModal} />
  </BasicModal>
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <Box p={2} height={"340px"} bgcolor={"#222526"}>
        <DialogTitle>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "20px",
              alignItems: "center",
            }}
          >
            <CustomTypography4
              sx={{
                fontWeight: 700,
                fontSize: "16px",
                lineHeight: "24px",
                color: "#FFFFFF",
              }}
            >
              Add a contact
            </CustomTypography4>

            <CloseButton
              onClick={handleClose}
              sx={{
                width: "36px",
                height: "36px",
                //border: "1px solid rgba(255, 255, 255, 0.15)"
              }}
            >
              <CloseIcon
                sx={{
                  color: "rgba(255, 255, 255, 0.5)",
                  width: "18px",
                  height: "18px",
                }}
              />
            </CloseButton>
          </Box>
        </DialogTitle>

        <DialogContent>
        <InputLabel
        htmlFor="input-with-icon-textfield"
        sx={{
          color: PRIMARY.dark,
          fontSize: "14px",
          lineHeight: "21px",
          fontWeight: 400,
          fontFamily: "Lato",
        }}
      >
        Type name or email address
      </InputLabel>

      <Autocomplete
        multiple
        freeSolo
        disableClearable
        id="tags-standard"
        options={users?.map((user) => `${user.full_name} (${user.email})`)} // Display name and email in suggestions
        getOptionLabel={(option) => option}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);
          if (params.inputValue !== "") {
            filtered.push(params.inputValue);
          }
          return filtered;
        }}
        value={selectedValues}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        onChange={handleSelectChange} // Handle unique values on selection
        onBlur={handleBlur} // Automatically accept the typed value
        renderTags={(value, getTagProps) =>
          value.map((option, index) => {
            const match = option.match(/(.*)\((.*)\)/); // Match the format "Name (Email)"
            const displayValue = match ? match[1].trim() : option; // Display only name in the tag
            return (
              <Chip
                style={{ top: "-5px" }}
                key={index}
                label={displayValue} 
                deleteIcon={<CloseRoundedIcon 
                  sx={{height:"16px",
                    width:"16px",
                   }} />} 
                sx={{
                  borderRadius: "8px",
                  backgroundColor: "rgba(26, 28, 29, 1)",
                  color: "#fff",
                  fontSize: "14px",
                  lineHeight: "21px",
                  marginBottom: "3rem",
                  height: "36px",
                }}
                {...getTagProps({ index })}
              />
            );
          })
        }
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            
      // Conditionally render the placeholder:
      // Show the placeholder "Person" only when there is no input or selection
      placeholder={
        params.inputProps.value.length === 0 && selectedValues.length === 0
          ? "Person"
          : ""
      }

            sx={{
              mt: 3,
              color: "var(--ffffff, #FFF)",
              fontFeatureSettings: "'clig' off, 'liga' off'",
              "& .MuiInput-underline:before": {
                borderBottom: `2px solid ${WHITE[50]}`,
                paddingBlock: "12px",
              },
              "& .MuiInput-underline:after": {
                borderBottom: `1px solid ${WHITE[50]}`,
                paddingBlock: "12px",
              },
              "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                borderBottom: `2px solid ${WHITE[50]}`,
                paddingBlock: "12px",
              },
              "& .MuiInputBase-input": {
                padding: "25px 0px",
                color: "white",
                paddingBlock: "12px",
              },
             
              
            }}
            

          />
        )}
      />
        </DialogContent>

        <DialogActions sx={{padding:3,mt:1}}>
          <Box mt={2} sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
            <OutlinedButton variant="outlined" onClick={handleClose} 
            sx={{ px: 3, 
            width: "124px",
             height: "46px",
             "&:hover": {
              borderColor: WHITE[50],
              backgroundColor: "rgba(255, 255, 255, 0.1)",
            }, }}>
              <CustomTypography4 sx={{ fontSize: "16px",fontFamily:"Lato" }}>Cancel</CustomTypography4>
            </OutlinedButton>

            <Button
              variant="contained"
              sx={{
                textTransform: "none",
                px: 2,
                background: theme.palette.primary.info,
                width: "164px",
                height: "46px",
                borderRadius: "10px",
                "&:hover": {
                  backgroundColor: "#007CD3",
                },
              }}
             // onClick={handleSendInvite}
             onClick={() => {
              handleSendInvite();
              setOpenModal(true)
             }}
            >
              <CustomTypography4 sx={{ fontSize: "16px",fontFamily:"Lato" }}>Invite</CustomTypography4>
            </Button>
          </Box>
        </DialogActions>
      </Box>
    </Dialog>
    </>
  );
};

InviteModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  setInvitees: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
};

export default InviteModal;
