import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  Button,
  Chip,
  TextField,
  Autocomplete,
  InputLabel,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/material/styles";
import { createFilterOptions } from "@mui/material";
import { PRIMARY } from "../../../theme/Palette";
import {
  OutlinedButton,
  CloseButton,
} from "../../../components/Butons/CustomButton";
import { CustomTypography4 } from "../../../components/Typography/Typography";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { WHITE } from "../../../theme/Palette";
import { ToastContainer, toast } from "react-toastify"; // Import toast components
import "react-toastify/dist/ReactToastify.css";

const filter = createFilterOptions();

const InviteModal = ({ open, handleClose, setInvitees, onNext }) => {
  const theme = useTheme();
  const [options, setOptions] = useState([]);
  const [selectedValues, setSelectedValues] = useState([]);
  const [error, setError] = useState(false);

  const handleSendInvite = () => {
    toast.success("Invite sent sucessfully.",{
        style:{
          fontWeight: 600,
          fontSize: "16px",
          lineHeight: "27px",
          fontFamily: "Lato",
          backgroundColor:"rgba(34, 37, 38, 1)",
          color:'rgba(255, 255, 255, 1)'
        },
        autoClose: 1000, 
        
            });
    if (selectedValues.length === 0) {
      setError(true);
      setTimeout(() => {
        setError("");
      }, 1000);
    } else {
      setInvitees(selectedValues);
      setError(false); // Reset error
      onNext(); // Call onNext handler to proceed to the next step
      handleClose(); // Close the modal after sending invites
    }
  };

  return (
    <>

      <ToastContainer
        closeButton={false}
        toastStyle={{
          backgroundColor: PRIMARY.light, // Custom background color for toast
          color: "#fff", // Custom text color
        }}
      />

      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>

        <Box p={2} height={"340px"} bgcolor={"#222526"}>

          <DialogTitle>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "20px",
                alignItems: "center",
              }}
            >
              <CustomTypography4
                sx={{
                  fontWeight: 600,
                  fontSize: "16px",
                  lineHeight: "24px",
                  color: "#FFFFFF",
                  fontFamily: "Lato",

                }}
              >
                Invite to Call
              </CustomTypography4>

              <CloseButton
                onClick={handleClose}
                sx={{
                  width: "36px",
                  height: "36px",
                  border: "1px solid rgba(255, 255, 255, 0.15)", // Add border to close button
                }}
              >
                <CloseIcon
                  sx={{
                    color: "rgba(255, 255, 255, 0.5)",
                    width: "18px",
                    height: "18px",
                  }}
                />
              </CloseButton>
            </Box>
          </DialogTitle>

          <DialogContent>
            <InputLabel
              htmlFor="input-with-icon-textfield"
              sx={{
                color: "rgba(255, 255, 255, 0.5)",
                fontSize: "14px",
                lineHeight: "21px",
                fontWeight: 400,
                fontFamily: "Lato",
              }}
            >
              Type name or email address
            </InputLabel>

            <Autocomplete
              multiple
              id="tags-standard"
              options={options}
              freeSolo
              disableClearable // This prop hides the close icon in the input field
              getOptionLabel={(option) => option}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);
                if (params.inputValue !== "") {
                  filtered.push(params.inputValue);
                }

                return filtered;
              }}
              onChange={(event, newValue) => {
                setSelectedValues(newValue);
                const lastValue = newValue[newValue.length - 1];
                if (
                  typeof lastValue === "string" &&
                  !options.includes(lastValue)
                ) {
                  setOptions([...options, lastValue]);
                }
              }}
              sx={{
                maxHeight: "72px",
                overflowY: "auto", // Ensure vertical scrolling is enabled
                overflowX: "hidden", // Hide horizontal scroll bar
              }}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    style={{ top: "-4px", height: "35px" }}
                    key={index}
                    label={option}
                    deleteIcon={
                      <CloseRoundedIcon
                        sx={{
                          height: "16px",
                          width: "16px",
                        }}
                      />
                    }
                    sx={{
                      borderRadius: "8px",
                      backgroundColor: "rgba(26, 28, 29, 1)",
                      color: "#fff",
                      fontWeight: 600,
                      fontSize: "14px",
                      lineHeight: "21px",
                      marginBottom: "3rem",
                      height: "36px",
                      fontFamily: "Lato",
                      overflowX: "hidden", // Hide horizontal scroll bar for tags
                      whiteSpace: "nowrap", // Prevent text from wrapping
                    }}
                    {...getTagProps({ index })}
                  />

                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  placeholder={
                    params.inputProps.value.length === 0 &&
                      selectedValues.length === 0
                      ? "Person"
                      : ""
                  }
                  sx={{
                    mt: 1,
                    color: "var(--ffffff, #FFF)",
                    fontFeatureSettings: "'clig' off, 'liga' off",
                    fontFamily: "Lato",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: "700",
                    lineHeight: "23px",
                    "& .MuiInput-underline:before": {
                      borderBottom: `1px solid rgba(245, 245, 250, 0.4))`, // Thicker custom border before focus
                    },
                    "& .MuiInput-underline:after": {
                      borderBottom: `1px solid rrgba(245, 245, 250, 0.4)`, // Thicker custom border after focus
                    },
                    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                      borderBottom: `1px solidrgba(245, 245, 250, 0.4)`, // Custom border on hover
                    },
                    "& .MuiInputBase-input": {
                      paddingBottom: "-4px",
                      padding: "35px 10px",
                      color: "white", // Ensure text color is white
                      overflowX: "hidden", // Hide horizontal scroll bar in input
                    },
                  }}
                />
              )}
            />

            {error && (
              <Typography
                sx={{
                  color: "red",
                  fontSize: "14px",
                  marginTop: "10px",
                  fontFamily: "Lato",
                }}
              >
                Please enter at least one invitee!
              </Typography>
            )}
          </DialogContent>

          <DialogActions>
            <Box
              mt={4}
              p={2}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <OutlinedButton
                variant="outlined"
                onClick={handleClose}
                sx={{
                  px: 3, width: "124px", height: "46px",
                  "&:hover": {
                    borderColor: WHITE[50],
                    backgroundColor: "rgba(255, 255, 255, 0.1)",
                  },
                }}
              >
                <CustomTypography4 sx={{ fontSize: "16px" }}>
                  Cancel
                </CustomTypography4>
              </OutlinedButton>

              <Button
                variant="contained"
                sx={{
                  textTransform: "none",
                  px: 2,
                  background: theme.palette.primary.info,
                  width: "164px",
                  height: "46px",
                  borderRadius: "10px",
                  fontSize: "16px",
                  "&:hover": {
                    backgroundColor: "#007CD3",
                  },

                }}
                onClick={handleSendInvite}
              >
                <CustomTypography4 sx={{ fontSize: "16px" }}>
                  {selectedValues.length > 0
                    ? `Send ${selectedValues.length} Invite${selectedValues.length > 2 ? "s" : ""
                    }`
                    : "Send Invite"}
                </CustomTypography4>
              </Button>
            </Box>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
};

InviteModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  setInvitees: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
};

export default InviteModal;
