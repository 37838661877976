import React, {
    useState,
    useEffect,
    useImperativeHandle,
    forwardRef,
  } from "react";
  import {
    Box,
    Typography,
    IconButton,
    Autocomplete,
    TextField,
  } from "@mui/material";
  import CloseIcon from "@mui/icons-material/Close";
  import PropTypes from "prop-types";
  import { useDispatch, useSelector } from "react-redux";
  import {
    fetchLanguageDropdown,
    fetchCaptions,
  } from "../../../redux/slices/MeetingSlice";
  import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
  
  const Transcript = forwardRef(({ quickCallId, setShowTranscript }, ref) => {
    const dispatch = useDispatch();
    const [selectedLanguage, setSelectedLanguage] = useState(null);
    const [captionsEntries, setCaptionsEntries] = useState([]);
    const { languageDropdown, isLoading } = useSelector((state) => state.meeting);
  
    useEffect(() => {
      if (quickCallId) {
        dispatch(fetchLanguageDropdown({ meeting_id: quickCallId }))
          .then((res) => {
            const fetchedLanguages = res.payload;
            const originalLanguage = fetchedLanguages.find(
              (lang) => lang.name === "Original (Without Translation)"
            );
            if (originalLanguage) {
              setSelectedLanguage(originalLanguage); // Set original language as default
            }
          })
          .catch((err) => {
            console.error("Error fetching languages", err);
          });
      }
    }, [quickCallId, dispatch]);
  
    useEffect(() => {
      if (quickCallId && selectedLanguage) {
        triggerCaptionsAPI(selectedLanguage.name);
      }
    }, [selectedLanguage]);
  
    const triggerCaptionsAPI = (language) => {
      if (quickCallId && language) {
        dispatch(fetchCaptions({ quick_call_id: quickCallId, language }))
          .then((res) => {
            if (res.meta.requestStatus === "fulfilled") {
              const captionsArray =
                res.payload?.participants.map((participant) => ({
                  name: participant.participant_name,
                  text: participant.translated_text,
                })) || [];
              setCaptionsEntries(captionsArray); // Update state with parsed participants and their texts
            } else {
              console.error("Failed to fetch captions", res.payload);
            }
          })
          .catch((error) => {
            console.error("Error triggering captions API", error);
          });
      }
    };
  
    useImperativeHandle(ref, () => ({
      triggerCaptionsAPI,
    }));
  
    if (isLoading && !languageDropdown.length) {
      return (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100%"
        >
          <Typography variant="body1">Loading...</Typography>
        </Box>
      );
    }
  
    const languageOptions = languageDropdown?.filter((lang) => lang.name) || [];
  
    return (
      <Box
        sx={{
          width: "100%",
          height: "100%",
          padding: "16px",
          color: "#FFF",
          borderRadius: "8px",
          boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between", // Places the IconButton on the right side
            width: "100%", // Make the container take full width
          }}
        >
          <Typography
            variant="h5"
            sx={{
              fontWeight: 600,
              fontSize: "18px",
              lineHeight: "27px",
              fontFamily: "Lato",
              color: "rgba(255, 255, 255, 0.9)",
            }}
          >
            Transcript
          </Typography>
          <IconButton
            size="small"
            sx={{
              color: "white",
              border: "1px solid #444",
              borderRadius: "10px",
              width: "36px",
              height: "36px",
            }}
            onClick={() => setShowTranscript(false)}
          >
            <CloseIcon
              sx={{
                height: "18px",
                widows: "18px",
                color: "rgba(255, 255, 255, 0.5)",
              }}
            />
          </IconButton>
        </Box>
  
        {/* Language Selection Dropdown */}
        <Box mt={3}>
          <Typography
            variant="body2"
            sx={{
              color: "rgba(255, 255, 255, 0.5)",
              fontSize: "14px",
              lineHeight: "21px",
              fontFamily: "Lato",
            }}
          >
            Your preferred language
          </Typography>
          <Autocomplete
            fullWidth
            options={languageOptions}
            getOptionLabel={(option) => option.name || ""}
            value={selectedLanguage}
            onChange={(event, newValue) => setSelectedLanguage(newValue)}
            popupIcon={
              <KeyboardArrowDownIcon
                sx={{ height: "18px", width: "18px", color: "red" }}
              />
            }
            clearIcon={null} // Hide the clear icon
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Select Language"
                variant="standard"
                InputProps={{
                  ...params.InputProps,
                  style: {
                    paddingBlock: "10px",
                    fontSize: "14px",
                    fontFamily: "Lato",
                  },
                }}
                //
  
                sx={{
                  "& .MuiInputBase-input::placeholder": {
                    fontSize: "15px", // Set placeholder font size
                    fontWeight: 500, // Set placeholder font weight
                    color: "rgba(255, 255, 255, 0.5)", // Set placeholder color
                  },
  
                  // Underline styles
                  "& .MuiInput-underline:before": {
                    borderBottomColor: "rgba(245, 245, 250, 0.4)", // Default underline color
                  },
                  "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                    borderBottomColor: "rgba(245, 245, 250, 0.4)", // Underline color on hover
                  },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: "rgba(245, 245, 250, 0.4)", // Underline color when focused
                  },
                  // Icon styles
                  "& .MuiSvgIcon-root": {
                    color: "rgba(255, 255, 255, 0.5)", // Set icon color
                    width: "18px",
                    height: "18px",
                  },
                }}
              />
            )}
          />
        </Box>
  
        {/* Captions Display */}
        <Box sx={{ marginTop: "30px" }}>
          {captionsEntries.length > 0 ? (
            captionsEntries.map((caption, index) => (
              <Typography
                key={index}
                variant="body2"
                sx={{ marginBottom: "8px", fontSize: "14px", lineHeight: "21px" }}
              >
                <strong
                  style={{
                    fontWeight: 400,
                    fontSize: "13px",
                    fontFamily: "Lato",
                    color: "rgba(255, 255, 255, 0.9)",
                  }}
                >
                  {caption.name}:
                </strong>
                <br />
                <span
                  style={{
                    fontWeight: 400,
                    fontSize: "13px",
                    fontFamily: "Lato",
                    color: "rgba(255, 255, 255, 0.9)",
                  }}
                >
                  {caption.text}
                </span>
              </Typography>
            ))
          ) : (
            ""
          )}
        </Box>
      </Box>
    );
  });
  
  // Add display name to the Transcript component to resolve the ESLint warning
  Transcript.displayName = "Transcript";
  
  Transcript.propTypes = {
    quickCallId: PropTypes.string.isRequired,
    setShowTranscript: PropTypes.func.isRequired,
  };
  
  export default Transcript;
  