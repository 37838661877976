import React, { useState, useEffect, forwardRef } from "react";
import {
  Box,
  Typography,
  IconButton,
  Autocomplete,
  TextField,
  Button,
  useTheme, Switch
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { fetchLanguageDropdown } from "../../../redux/slices/MeetingSlice";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import AutoAwesomeSharpIcon from "@mui/icons-material/AutoAwesomeSharp";


const TranslationPrefrence = forwardRef(({ quickCallId, setActivePanel }) => {
  const dispatch = useDispatch();
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const { languageDropdown, isLoading } = useSelector((state) => state.meeting);
  const theme = useTheme();

  useEffect(() => {
    if (quickCallId) {
      dispatch(fetchLanguageDropdown({ meeting_id: quickCallId }))
        .then((res) => {
          const fetchedLanguages = res.payload;
          const originalLanguage = fetchedLanguages.find(
            (lang) => lang.name === "Original (Without Translation)"
          );
          if (originalLanguage) {
            setSelectedLanguage(originalLanguage); // Set original language as default
          }
        })
        .catch((err) => {
          console.error("Error fetching languages", err);
        });
    }
  }, [quickCallId, dispatch]);

  if (isLoading && !languageDropdown.length) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100%"
      >
        <Typography variant="body1">Loading...</Typography>
      </Box>
    );
  }

  const languageOptions = languageDropdown?.filter((lang) => lang.name) || [];

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        padding: "16px 16px 0",
        color: "#FFF",
        borderRadius: "8px",
        boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
        display: "flex",
        flexDirection: "column", justifyContent:"space-between", 
      }}
    >
      <Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between", // Places the IconButton on the right side
          width: "100%", // Make the container take full width
        }}
      >
        <Typography
          variant="h5"
          sx={{
            fontWeight: 600,
            fontSize: "18px",
            lineHeight: "27px",
            fontFamily: "Lato",
            color: "rgba(255, 255, 255, 0.9)",
          }}
        >
          Translation settings
        </Typography>
        <IconButton
          size="small"
          sx={{
            color: "white",
            border: "1px solid #444",
            borderRadius: "10px",
            width: "36px",
            height: "36px",
          }}
          onClick={() => setActivePanel(null)}
        >
          <CloseIcon
            sx={{
              height: "18px",
              width: "18px",
              color: "rgba(255, 255, 255, 0.5)",
            }}
          />
        </IconButton>
      </Box>

      <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between", 
            padding: "4px 0",
            height: "22px",
            marginY:"20px",

            // Responsive Styles Using CSS Media Queries
            "@media (max-width: 1280px)": {
              height: "20px",
              padding: "3px 6px",
              borderRadius: "18px",
            },
            "@media (max-width: 960px)": {
              height: "18px",
              padding: "3px 4px",
              borderRadius: "16px",
            },
            "@media (max-width: 600px)": {
              height: "auto",
              padding: "2px 4px",
              borderRadius: "12px",
              marginRight: "0px",
            },
            "@media (max-width: 360px)": {
              flexDirection: "column",
              padding: "4px",
            },
          }}
        >
          <Typography
            variant="caption"
            sx={{
              color: "white",
              fontWeight: 700,
              fontFamily: "Lato",
              fontSize: "16px",
              lineHeight: "13.6px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center", // Center the content within the Typography

              "@media (max-width: 1280px)": {
                fontSize: "9px",
                lineHeight: "12px",
              },
              "@media (max-width: 960px)": {
                fontSize: "8px",
                lineHeight: "11px",
              },
              "@media (max-width: 600px)": {
                fontSize: "7px",
                lineHeight: "10px",
              },
              "@media (max-width: 360px)": {
                fontSize: "6px",
                lineHeight: "9px",
                textAlign: "center",
              },
            }}
          >
            <AutoAwesomeSharpIcon
              sx={{
                width: "10px",
                height: "10px",
                marginRight: "4px", // Adjust spacing between icon and text
                [window.matchMedia("(max-width: 1280px)").matches]: {
                  width: "9px",
                  height: "9px",
                },
                [window.matchMedia("(max-width: 960px)").matches]: {
                  width: "8px",
                  height: "8px",
                },
                [window.matchMedia("(max-width: 600px)").matches]: {
                  width: "7px",
                  height: "7px",
                },
                [window.matchMedia("(max-width: 360px)").matches]: {
                  width: "6px",
                  height: "6px",
                },
              }}
            />
            Enable Translation
          </Typography>
          <Switch
          sx={{
            marginLeft: "20px", // This moves the switch to the right side
            borderRadius: "12px",
            height: "22px",
            width: "43px",
            padding: 0, // Remove extra padding around the track
            "& .MuiSwitch-switchBase": {
              padding: "6px", // Adjust padding around the thumb
              top: "-3px", // Ensure the thumb is vertically centered (centered for a 16px thumb on a 22px track)
              color: "rgba(26, 28, 29, 1)", // Thumb color when not checked (black)
              "&.Mui-checked": {
                transform: "translateX(15px)", // Adjust thumb movement based on track width and thumb size
                color: "white", // Thumb color when checked
              },
              "&.Mui-checked + .MuiSwitch-track": {
                backgroundColor: "rgba(0, 146, 248, 1)", // Blue background when checked
                opacity: 1,
              },
            },
            "& .MuiSwitch-thumb": {
              ///height: "16px", // Thumb height
              width: "16px",  // Thumb width
              height: "16px"
            },
            "& .MuiSwitch-track": {
              borderRadius: "11px", // Rounded corners for the track
              backgroundColor: "rgba(66, 66, 77, 1)", // Background when not checked
              opacity: 1,
            },
          }}
        />
        </Box>

      {/* Language Selection Dropdown */}
      <Box mt={3}>
        <Typography
          variant="body2"
          sx={{
            color: "rgba(255, 255, 255, 0.5)",
            fontSize: "14px",
            lineHeight: "21px",
            fontFamily: "Lato",
          }}
        >
          Type
        </Typography>
        <Autocomplete
          fullWidth
          options={languageOptions}
          getOptionLabel={(option) => option.name || ""}
          value={selectedLanguage}
          onChange={(event, newValue) => setSelectedLanguage(newValue)}
          clearIcon={null} // Hide the close icon
          popupIcon={
            <KeyboardArrowDownOutlinedIcon
              sx={{
                color: "rgba(255, 255, 255, 0.5)",
                width: "18px",
                height: "18px",
              }}
            />
          }
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Select Type"
              variant="standard"
              InputProps={{
                ...params.InputProps,
                style: {
                  paddingBlock: "10px",
                  fontSize: "14px",
                  fontFamily: "Lato",
                },
              }}
              sx={{
                "& .MuiInputBase-input::placeholder": {
                  fontSize: "15px", // Set placeholder font size
                  fontWeight: 500, // Set placeholder font weight
                  color: "rgba(255, 255, 255, 0.5)", // Set placeholder color
                },

                // Underline styles
                "& .MuiInput-underline:before": {
                  borderBottomColor: "rgba(245, 245, 250, 0.4)", // Default underline color
                },
                "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                  borderBottomColor: "rgba(245, 245, 250, 0.4)", // Underline color on hover
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: "rgba(245, 245, 250, 0.4)", // Underline color when focused
                },
                // Icon styles
                "& .MuiSvgIcon-root": {
                  color: "rgba(255, 255, 255, 0.5)", // Set icon color
                  width: "18px",
                  height: "18px",
                },
              }}
            />
          )}
        />
      </Box>

      <Box sx={{ marginTop: "30px" }}>
        <Typography
          variant="body2"
          sx={{
            color: "rgba(255, 255, 255, 0.5)",
            fontSize: "14px",
            lineHeight: "21px",
            fontFamily: "Lato",
            marginBottom: "10px",
          }}
        >
         Your Preference
        </Typography>
        <Autocomplete
          fullWidth
          options={languageOptions}
          getOptionLabel={(option) => option.name || ""}
          value={selectedLanguage}
          onChange={(event, newValue) => setSelectedLanguage(newValue)}
          clearIcon={null} // Hide the close icon
          popupIcon={
            <KeyboardArrowDownOutlinedIcon
              sx={{
                color: "rgba(255, 255, 255, 0.5)",
                width: "18px",
                height: "18px",
              }}
            />
          }
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Select Language"
              variant="standard"
              InputProps={{
                ...params.InputProps,
                style: {
                  paddingBlock: "10px",
                  fontSize: "14px",
                  fontFamily: "Lato",
                },
              }}
              sx={{
                "& .MuiInputBase-input::placeholder": {
                  fontSize: "15px", // Set placeholder font size
                  fontWeight: 500, // Set placeholder font weight
                  color: "rgba(255, 255, 255, 0.5)", // Set placeholder color
                },

                // Underline styles
                "& .MuiInput-underline:before": {
                  borderBottomColor: "rgba(245, 245, 250, 0.4)", // Default underline color
                },
                "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                  borderBottomColor: "rgba(245, 245, 250, 0.4)", // Underline color on hover
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: "rgba(245, 245, 250, 0.4)", // Underline color when focused
                },
                // Icon styles
                "& .MuiSvgIcon-root": {
                  color: "rgba(255, 255, 255, 0.5)", // Set icon color
                  width: "18px",
                  height: "18px",
                },
              }}
            />
          )}
        />
      </Box>

      <Box sx={{ marginTop: "30px" }}>
        <Typography
          variant="body2"
          sx={{
            color: "rgba(255, 255, 255, 0.5)",
            fontSize: "14px",
            lineHeight: "21px",
            fontFamily: "Lato",
            marginBottom: "10px",
          }}
        >
         Translated to
        </Typography>
        <Autocomplete
          fullWidth
          options={languageOptions}
          getOptionLabel={(option) => option.name || ""}
          value={selectedLanguage}
          onChange={(event, newValue) => setSelectedLanguage(newValue)}
          clearIcon={null} // Hide the close icon
          popupIcon={
            <KeyboardArrowDownOutlinedIcon
              sx={{
                color: "rgba(255, 255, 255, 0.5)",
                width: "18px",
                height: "18px",
              }}
            />
          }
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Select Language"
              variant="standard"
              InputProps={{
                ...params.InputProps,
                style: {
                  paddingBlock: "10px",
                  fontSize: "14px",
                  fontFamily: "Lato",
                },
              }}
              sx={{
                "& .MuiInputBase-input::placeholder": {
                  fontSize: "15px", // Set placeholder font size
                  fontWeight: 500, // Set placeholder font weight
                  color: "rgba(255, 255, 255, 0.5)", // Set placeholder color
                },

                // Underline styles
                "& .MuiInput-underline:before": {
                  borderBottomColor: "rgba(245, 245, 250, 0.4)", // Default underline color
                },
                "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                  borderBottomColor: "rgba(245, 245, 250, 0.4)", // Underline color on hover
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: "rgba(245, 245, 250, 0.4)", // Underline color when focused
                },
                // Icon styles
                "& .MuiSvgIcon-root": {
                  color: "rgba(255, 255, 255, 0.5)", // Set icon color
                  width: "18px",
                  height: "18px",
                },
              }}
            />
          )}
        />
      </Box>

      </Box>
      <Box>

        <Button
          variant="contained"
          sx={{
            textTransform: "none",
            width: "100%",
            borderRadius: "8px",
            marginTop: "10px",
            padding: "8px 40px",
            background: theme.palette.primary.info,
            color: theme.palette.primary.white,
            fontSize: "16px",
            fontFamily: "Lato",
            ":hover": {
              background: "#007CD3",
              color: theme.palette.primary.white,
            },
          }}
        >
          Save
        </Button>
      </Box>
    </Box>
  );
});

TranslationPrefrence.displayName = "TranslationPrefrence";

TranslationPrefrence.propTypes = {
  quickCallId: PropTypes.string.isRequired,
  setActivePanel: PropTypes.func.isRequired,
};

export default TranslationPrefrence;
