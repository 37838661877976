import React, { useState } from "react";
import {
  Box,
  Typography,
  Avatar,
  IconButton,
  Button,
  Divider,
} from "@mui/material";
import MicIcon from "@mui/icons-material/Mic";
import MicOffIcon from "@mui/icons-material/MicOff";
import user from "../../../assets/images/videocall/girlonline.jpg";
import user2 from "../../../assets/images/videocall/men-img-a.jpg";
import user3 from "../../../assets/images/videocall/men-img-b.jpg";
import user4 from "../../../assets/images/videocall/girl-img-a.jpg";
import LinkIcon from "@mui/icons-material/Link";
import { PRIMARY } from "../../../theme/Palette";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import SyncIcon from "@mui/icons-material/Sync";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import DoneSharpIcon from "@mui/icons-material/DoneSharp";
//import InviteModal from "./InviteModal";
import { ToastContainer, toast } from "react-toastify"; // Import toast components
import "react-toastify/dist/ReactToastify.css";
import InviteModal from "../../businessUser/Calls/InviteToChat";

const participants = [
  {
    name: "Marvin Lambert",
    language: "English",
    insideMeeting: true,
    mic: true,
    avatar: user,
  }, //avatar for img for users
  {
    name: "Teresa Lloyd",
    language: "Spanish",
    insideMeeting: true,
    mic: false,
    avatar: user2,
  },
  {
    name: "Fred Haynes",
    language: "Spanish",
    insideMeeting: true,
    mic: true,
    avatar: user3,
  },
  {
    name: "Rose Peters",
    language: "Spanish",
    insideMeeting: true,
    mic: true,
    avatar: user4,
  },
  {
    name: "Marvin Lambert",
    language: "English",
    insideMeeting: false,
    avatar: user2,
  },
  //{ name: "Fred Haynes", language: "Spanish", insideMeeting: true, mic: true,avatar: user3 },
  // { name: "Rose Peters", language: "Spanish", insideMeeting: true, mic: true,avatar: user4 },
  // { name: "Marvin Lambert", language: "English", insideMeeting: false,avatar: user2 },
];

const Participants = ({ setShowParticipants }) => {
  const [inviteButtonText, setInviteButtonText] = useState(" Waiting room");
  const [inviteButtonIcon, setInviteButtonIcon] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [invitees, setInvitees] = useState([]);
  console.log(invitees);

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleInviteClick = () => {
    setInviteButtonText("Invited");
    setInviteButtonIcon(true);
  };

  const handleCloseParticipants = () => {
    setShowParticipants(false); // This will update the parent component to unselect the Participants icon
  };

  
  const handleUpdate = () => {
    toast.success("copied link successfully.",{
style:{
  fontWeight: 600,
  fontSize: "16px",
  lineHeight: "27px",
  fontFamily: "Lato",
  backgroundColor:"rgba(34, 37, 38, 1)",
  color:'rgba(255, 255, 255, 1)'
},
autoClose: 2000, 

    });
    
  };

  const handleReject = () => {
    toast.error("Request rejected.",{
style:{
  fontWeight: 600,
  fontSize: "16px",
  lineHeight: "27px",
  fontFamily: "Lato",
  backgroundColor:"rgba(34, 37, 38, 1)",
  color:'rgba(255, 255, 255, 1)'
},
autoClose: 2000, 

    });
    
  };
  const handelAccept = () => {
    toast.success("Request accepted.",{
style:{
  fontWeight: 600,
  fontSize: "16px",
  lineHeight: "27px",
  fontFamily: "Lato",
  backgroundColor:"rgba(34, 37, 38, 1)",
  color:'rgba(255, 255, 255, 1)'
},
autoClose: 2000, 

    });
    
  };


  

  return (
    <Box sx={{       width: "100%",
      height: "100%",
      backgroundColor: "rgba(34, 37, 38, 1)",
      color: "white",
      borderRadius: 2,
      overflow: "hidden",
      display: "flex",
      flexDirection: "column", }}>
        
           <ToastContainer
        closeButton={false}
        toastStyle={{
          backgroundColor: PRIMARY.light,
          color: "#fff", // Custom text color
        }}
      />
      <Box
        sx={{
          padding: 0,
          borderBottom: "0px solid #333",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: "0rem",
          marginTop: "1rem",
        }}
      >
        <Box display="flex" justifyContent="space-between" width="100%">
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: "18px",
              lineHeight: "27px",
              fontFamily: "Lato",
            }}
            variant="h6"
          >
            Participants
          </Typography>
          <IconButton
            onClick={() => handleCloseParticipants()}
            size="small"
            sx={{
              color: "white",
              border: "1px solid #444",
              borderRadius: "10px",
              width: "36px",
              height: "36px",
            }}
          >
            <CloseIcon
              sx={{
                color: "rgba(255, 255, 255, 0.5)",
                height: "18px",
                width: "18px",
              }}
            />
          </IconButton>
        </Box>
      </Box>

      <Box sx={{ padding: 0, marginTop: "0.6rem" }}>
        <Typography
          variant="subtitle1"
          sx={{
            marginBottom: "0.2rem",
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "21px",
            fontFamily: "Lato",
            color: "rgba(255, 255, 255, 0.5)",
          }}
        >
          Inside meeting
        </Typography>
        {participants
          .filter((p) => p.insideMeeting)
          .map((participant, index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                background: PRIMARY.darker,
                padding: "10px",
                marginBottom: "1px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Avatar
                  sx={{
                    marginRight: 2,
                    height: "36px",
                    width: "36px",
                    borderRadius: "10px",
                  }}
                  variant="rounded"
                  src={participant.avatar}
                >
                  {participant.name.charAt(0)}
                </Avatar>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{
                      fontWeight: 700,
                      fontSize: "14px",
                      fontFamily: "Lato",
                    }}
                  >
                    {participant.name}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      fontWeight: 400,
                      fontSize: "14px",
                      fontFamily: "Lato",
                    }}
                    color="gray"
                  >
                    {participant.language}
                  </Typography>
                </Box>
              </Box>
              <IconButton
                // size="small"
                sx={{
                  color: participant.mic ? "white" : "gray",
                  background: participant.mic
                    ? "rgba(40, 195, 69, 1)"
                    : "rgba(26, 28, 29, 1))",
                  borderRadius: "10px",
                  border: "1px solid rgba(255, 255, 255, 0.15)",
                  height: "36px",
                  width: "36px",
                }}
              >
                {participant.mic ? (
                  <MicIcon
                    sx={{
                      height: "18px",
                      width: "18px",
                      color: "rgba(255, 255, 255, 1)",
                    }}
                  />
                ) : (
                  <MicOffIcon
                    sx={{
                      height: "18px",
                      width: "18px",
                      color: "rgba(255, 255, 255, 0.5)",
                    }}
                  />
                )}
              </IconButton>
            </Box>
          ))}
      </Box>

      <Divider sx={{ borderColor: "#333" }} />

      <Box sx={{ padding: 0.6, mt: 2 }}>
        <Typography
          variant="subtitle1"
          sx={{
            marginBottom: 1,
            fontWeight: 400,
            fontSize: "13px",
            lineHeight: "21px",
            fontFamily: "Lato",
            color: "rgba(255, 255, 255, 0.5)",
          }}
        >
          {inviteButtonText}
        </Typography>
        {participants
          .filter((p) => !p.insideMeeting)
          .map((participant, index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                background: PRIMARY.darker,
                padding: "10px",
                marginBottom: "1px",
              }}
            >
              <Box sx={{ display: "flex" }}>
                <Avatar sx={{ marginRight: 2 }} variant="rounded" src={user}>
                  A
                </Avatar>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{
                      fontWeight: 600,
                      fontSize: "13.8px",
                      fontFamily: "Lato",
                    }}
                  >
                    {participant.name}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      fontWeight: 400,
                      fontSize: "13px",
                      fontFamily: "Lato",
                    }}
                    color="gray"
                  >
                    {participant.language}
                  </Typography>
                </Box>
              </Box>
              {!inviteButtonIcon ? (
                <Box>
                  <IconButton
                    sx={{
                      color: "white",
                      marginRight: 1,
                      border: "1px solid #444",
                      backgroundColor: "rgba(235, 87, 87, 1)",
                      borderRadius: "10px",
                      width: "34px",
                      height: "34px",
                      "&:hover": {
                        backgroundColor: "rgba(200, 70, 70, 1)", // Hover color
                      },
                    }}
                    onClick={handleReject}
                  >
                        
                    <CloseSharpIcon
                      sx={{
                        height: "18px",
                        width: "19px",
                        color: "rgba(255, 255, 255, 1)",
                      }}
                    />
                  </IconButton>

                  <IconButton
                    sx={{
                      color: "white",
                      border: "1px solid #444",
                      backgroundColor: "rgba(40, 195, 69, 1)",
                      borderRadius: "10px",
                      width: "34px",
                      height: "34px",
                      "&:hover": {
                        backgroundColor: "rgba(30, 160, 55, 1)", // Darker shade on hover
                      },
                    }}
                    onClick={handelAccept}
                  >
                    <DoneSharpIcon
                      sx={{
                        height: "18px",
                        width: "19px",
                        color: "rgba(255, 255, 255, 1)",
                      }}
                    />
                  </IconButton>
                </Box>
              ) : (
                <Box>
                  <IconButton
                    size="small"
                    sx={{
                      border: `1px solid #FFFFFF80`,
                      color: "#FFFFFF80",
                      borderRadius: "10px",
                      height: "36px",
                      width: "36px",
                      padding: "10px",
                      "&:hover": { backgroundColor: "#444B52" },
                    }}
                  >
                    <SyncIcon
                      sx={{
                        color: "rgba(255, 255, 255, 0.5)",
                        fontSize: "18px",
                      }}
                    />
                  </IconButton>
                </Box>
              )}
            </Box>
          ))}
      </Box>

      <Divider sx={{ borderColor: "#333" }} />

      <Box
        sx={{
          mt: 9,
          padding: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Button
          variant="text"
          startIcon={<LinkIcon sx={{ width: "18px", height: "18px" }} />}
          sx={{
            // backgroundColor: "#007bff",
            color: "white",
            fontFamily: "Lato",
            lineHeight: "19.2px",
            fontWeight: 600,
            fontSize: "16px",
            textTransform: "none",
            borderRadius: 2,
            padding: "8px 16px",
            "&:hover": {
              // backgroundColor: "#0056b3",
            },
          }}
          onClick={handleUpdate}
        >
          Copy Link
        </Button>
        <Button
          variant="contained"
          fullWidth
          sx={{
            backgroundColor: "rgba(0, 146, 248, 1)",
            color: "white",
            textTransform: "none",
            borderRadius: 2,
            padding: "8px 16px", fontSize: "16px", fontFamily: "Lato",
            "&:hover": {
              backgroundColor: "#007CD3",
            },
          }}
          onClick= {()=>{handleInviteClick, setOpenModal(true)}}
        >
          Invite
        </Button>
      </Box>

      <InviteModal
        open={openModal}
        handleClose={handleCloseModal}
        setInvitees={setInvitees} onNext={handleCloseModal}
      />

    </Box>
  );
};

Participants.propTypes = {
  setShowParticipants: PropTypes.func.isRequired,
};

export default Participants;
