import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Divider,
  Stack,
  MenuItem,
  Avatar,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import DoNotDisturbOnIcon from "@mui/icons-material/DoNotDisturbOn";
import DoDisturbAltIcon from "@mui/icons-material/DoDisturbAlt";
import CloseIcon from "@mui/icons-material/Close";
import MenuPopover from "../components/menu-popover/MenuPopover";
import { useDispatch } from "react-redux";
import { logout } from "../redux/slices/AuthSlice";
import { navHeader } from "../redux/slices/ThemeSlice";
import {
  CustomSubtitle,
  CustomTypography4,
} from "../components/Typography/Typography";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

import PassWordChange from "../assets/icons/profile/encrypted.svg";
import ManageSub from "../assets/icons/profile/stars.svg";
import EditProfile from "../assets/icons/profile/person_edit.svg";
import Logout from "../assets/icons/profile/move_item.svg";

const OPTIONS = [
  {
    label: "Manage Subscription",
    img: ManageSub,
    linkTo: "/dashboard/settings/manage-subscription",
  },
  {
    label: "Change Password",
    img: PassWordChange,
    linkTo: "/dashboard/settings/change-password",
  },
  {
    label: "Edit Profile",
    img: EditProfile,
    linkTo: "/dashboard/settings/edit-profile",
  },
];

const STATUS_OPTIONS = [
  {
    label: "Available",
    icon: (
      <CheckCircleIcon
        style={{
          color: "#68FC5A",
          backgroundColor: "white",
          borderRadius: "50%",
        }}
      />
    ),
  },
  {
    label: "Away",
    icon: (
      <AccessTimeFilledIcon
        style={{
          color: "#F6933E",
          backgroundColor: "white",
          borderRadius: "50%",
        }}
      />
    ),
  },
  {
    label: "Busy",
    icon: (
      <AccessTimeFilledIcon
        style={{
          color: "#F25542",
          backgroundColor: "white",
          borderRadius: "50%",
        }}
      />
    ),
  },
  {
    label: "Do not disturb",
    icon: (
      <DoNotDisturbOnIcon
        style={{
          color: "#FF5E2D",
          backgroundColor: "white",
          borderRadius: "50%",
        }}
      />
    ),
  },
  {
    label: "Offline",
    icon: (
      <DoDisturbAltIcon
        style={{
          color: "white",
          backgroundColor: "transparent",
          borderRadius: "50%",
        }}
      />
    ),
  },
];

export default function AccountPopover({ openPopover, setOpenPopover }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [statusModalOpen, setStatusModalOpen] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false); // State for confirmation modal
  const statusRef = useRef(null);
  const [selectedStatus, setSelectedStatus] = useState({
    label: "Set Status",
    icon: null,
  });
  const [modalPosition, setModalPosition] = useState({ top: 0, left: 0 });

  useEffect(() => {
    const updateModalPosition = () => {
      if (statusRef.current) {
        const rect = statusRef.current.getBoundingClientRect();
        setModalPosition({
          top: rect.bottom + window.scrollY + rect.height * 0.1,
          left: rect.left + window.scrollX,
        });
      }
    };

    window.addEventListener("resize", updateModalPosition);
    window.addEventListener("scroll", updateModalPosition);

    const observer = new MutationObserver(updateModalPosition);
    observer.observe(document.body, {
      attributes: true,
      childList: true,
      subtree: true,
    });

    return () => {
      window.removeEventListener("resize", updateModalPosition);
      window.removeEventListener("scroll", updateModalPosition);
      observer.disconnect();
    };
  }, [statusModalOpen]);

  const handleStatusOpen = () => {
    setStatusModalOpen(true);
    if (statusRef.current) {
      const rect = statusRef.current.getBoundingClientRect();
      setModalPosition({
        top: rect.bottom + window.scrollY + rect.height * 0.1,
        left: rect.left + window.scrollX,
      });
    }
  };

  const handleStatusClose = () => {
    setStatusModalOpen(false);
  };

  const handleClosePopover = () => {
    setOpenPopover(false);
  };

  const handleLogout = () => {
    setOpenConfirmModal(true); // Open confirmation modal when logout is clicked
  };

  const handleConfirmLogout = () => {
    try {
      dispatch(logout());
      dispatch(navHeader("Overview"));
      localStorage.clear();
      sessionStorage.clear();
      navigate("/auth/login", { replace: true });
      setOpenConfirmModal(false);
      handleClosePopover();
    } catch (error) {
      console.error("Error during logout:", error);
    }
  };

  const handleCloseConfirmModal = () => {
    setOpenConfirmModal(false); // Close the modal without logging out
  };

  const handleClickItem = (path) => {
    handleClosePopover();
    navigate(path, { replace: true });
  };

  const handleStatusSelect = (statusLabel, statusIcon) => {
    setSelectedStatus({ label: statusLabel, icon: statusIcon });
    handleStatusClose();
  };

  const email = localStorage.getItem("email");
  const username = localStorage.getItem("user_name");

  const user = {
    displayName: username,
    email: email,
  };

  return (
    <>
      <MenuPopover
        open={openPopover}
        onClose={handleClosePopover}
        sx={{
          width: "fit-content",
          p: 1,
          background: "var(--222526, #222526)",
          color: "#fff",
        }}
      >
        <Box
          sx={{
            height: "50px",
            my: 1.5,
            px: 2.5,
            display: "flex",
            justifyContent: "left",
            gap: 2,
          }}
        >
          <Avatar
            variant="rounded"
            src={user?.profile}
            sx={{ height: "40px", width: "40px", borderRadius: "8px" }}
          ></Avatar>
          <Box>
            <CustomTypography4>{user?.displayName}</CustomTypography4>
            <CustomSubtitle sx={{ color: "rgba(255, 255, 255, 0.5)" }}>
              {user?.email}
            </CustomSubtitle>
          </Box>
        </Box>

        <Stack sx={{ p: 1 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "10px",
            }}
          >
            <MenuItem
              sx={{
                display: "flex",
                justifyContent: "space-between",
                position: "relative",
              }}
              onClick={handleStatusOpen}
              ref={statusRef} // Reference to button for positioning
            >
              <Box display="flex" alignItems="center">
                {selectedStatus.icon && selectedStatus.icon}
                <CustomTypography4
                  sx={{
                    fontWeight: 400,
                    fontSize: "14px",
                    fontFamily: "Lato",
                    marginLeft: selectedStatus.icon ? "8px" : "0", // Add margin when icon is present
                  }}
                >
                  {selectedStatus.label}
                </CustomTypography4>
              </Box>
            </MenuItem>
            <KeyboardArrowRightIcon sx={{ height: "18px", width: "18px" }} />
          </Box>
          <Divider />
          {OPTIONS.map((option) => (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "10px",
              }}
              key={option.label}
            >
              <MenuItem
                key={option.label}
                onClick={() => handleClickItem(option.linkTo, option.label)}
              >
                <img src={option.img} style={{ marginRight: "10px" }} />
                <CustomTypography4
                  sx={{ fontSize: "12px", fontFamily: "Lato", fontWeight: 400 }}
                >
                  {option.label}
                </CustomTypography4>
              </MenuItem>
              <KeyboardArrowRightIcon sx={{ height: "18px", width: "18px" }} />
            </Box>
          ))}

          <MenuItem onClick={handleLogout}>
            <img src={Logout} style={{ marginRight: "10px" }} />
            <CustomTypography4
              sx={{ fontSize: "12px", fontFamily: "Lato", fontWeight: 400 }}
            >
              Logout
            </CustomTypography4>
          </MenuItem>
        </Stack>

        <Dialog
          open={statusModalOpen}
          onClose={handleStatusClose}
          PaperProps={{
            style: {
              background: "var(--222526, #222526)",
              borderRadius: "8px",
              overflow: "visible",
              position: "absolute",
              top: modalPosition.top,
              left: modalPosition.left,
              transform: "translateX(-20%) translateY(-30%)", // Adjust this to fine-tune the position
              transition: "top 0.2s, left 0.2s", // Smooth transitions on position changes
            },
          }}
        >
          <Box sx={{ p: 2, color: "#fff" }}>
            {STATUS_OPTIONS.map((option) => (
              <Box
                key={option.label}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  padding: "8px 0",
                  cursor: "pointer",
                  ":hover": { bgcolor: "rgba(255, 255, 255, 0.1)" },
                }}
                onClick={() => handleStatusSelect(option.label, option.icon)}
              >
                {option.icon}
                <Typography
                  sx={{ ml: 2, fontFamily: "Lato", fontSize: "12px" }}
                >
                  {option.label}
                </Typography>
              </Box>
            ))}
          </Box>
        </Dialog>
      </MenuPopover>

      {/* Confirmation Modal */}
      <Dialog
        open={openConfirmModal}
        onClose={handleCloseConfirmModal}
        maxWidth="sm"
        fullWidth
      >
        <Box p={2} bgcolor={"#222526"}>
          <DialogTitle>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "20px",
              }}
            >
              <CustomTypography4
                sx={{
                  fontWeight: 700,
                  fontSize: "16px",
                  lineHeight: "24px",
                  color: "#FFFFFF",
                  fontFamily: "Lato",
                }}
              ></CustomTypography4>
              <IconButton onClick={handleCloseConfirmModal}>
                <CloseIcon
                  sx={{
                    color: "rgba(255, 255, 255, 0.5)",
                    width: "18px",
                    height: "18px",
                  }}
                />
              </IconButton>
            </Box>
          </DialogTitle>

          <DialogContent sx={{ textAlign: "center" }}>
            <Typography
              sx={{
                color: "white",
                fontSize: "20px",
                lineHeight: "24px",
                fontWeight: 700,
                fontFamily: "Lato",
                marginBottom: "10px",
              }}
            >
              Are you sure you want to logout?
            </Typography>
            <Typography
              sx={{
                color: "rgba(255, 255, 255, 0.7)",
                fontSize: "14px",
                fontFamily: "Lato",
              }}
            >
              This action will log you out of your account.
            </Typography>
          </DialogContent>

          <DialogActions>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Button
                onClick={handleCloseConfirmModal}
                sx={{
                  width: "45%",
                  backgroundColor: "rgba(161, 161, 161, 0.1)",
                  borderRadius: "8px",
                  color: "rgba(255, 255, 255, 1)",
                  fontWeight: 700,
                  fontSize: "14px",
                  textTransform: "none",
                  fontFamily: "Lato",
                }}
              >
                No
              </Button>

              <Button
                variant="contained"
                sx={{
                  width: "45%",
                  borderRadius: "8px",
                  background: "#FF808B",
                  color: "rgba(255, 255, 255, 1)",
                  fontWeight: 700,
                  fontSize: "14px",
                  textTransform: "none",
                  fontFamily: "Lato",
                  "&:hover": {
                    backgroundColor: "#e76b77",
                  },
                }}
                onClick={handleConfirmLogout}
              >
                Yes, Logout
              </Button>
            </Box>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
}

AccountPopover.propTypes = {
  openPopover: PropTypes.bool,
  setOpenPopover: PropTypes.func,
};
