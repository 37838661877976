import React from "react";
import PropTypes from 'prop-types'
import { Button, Container, Stack } from "@mui/material";
// import { CenterItemBox } from "../../../components/Box/CustomBox";
import Sucessful from "../../../assets/images/Email/EmailSendImg.svg";
import CloseIcon from "@mui/icons-material/Close";
import { CloseButton } from "../../../components/Butons/CustomButton";
import {  CustomTypography1 } from "../../../components/Typography/Typography";
// import {
//   CustomSubtitle,
//   CustomTypography1,
// } from "../../../components/Typography/Typography";
// import { CloseButton } from "../../../components/Butons/CustomButton";

function InviteSucessfull({onClose}) {
  return (
      <Container sx={{boxShadow:"none"}}>
        {/* <CenterItemBox> */}
        <CloseButton  
            onClick={() => onClose(false)}   sx={{
      position: "absolute", 
      top: "16px", // Adjust the vertical position
      right: "16px", // Adjust the horizontal position
      zIndex: 10, 
      border:"1px solid rgbargba(255, 255, 255, 0.15)"
    }}>
          <CloseIcon  sx={{height:'18px',width:'18px',color:"rgba(128, 131, 163, 1)"}} />
        </CloseButton>


          <Stack spacing={2} sx={{display:"flex",justifyContent:"center",alignItems:"center"}}>
            <img height="200px" width="200px" src={Sucessful} />
          </Stack>
          <Stack mt={4}>
            <CustomTypography1 sx={{fontFamily:"Lato",fontSize:"26px",fontWeight:700}}>Invitation sent sucessfully </CustomTypography1>
          </Stack>
        

          <Stack
            mt={10}
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
                 <Button
  onClick={() => onClose(false)} // Close the modal when the "Ok" button is clicked
  sx={{
    backgroundColor: "rgba(0, 146, 248, 1)",
    fontFamily: "Lato",
    color: "rgba(255, 255, 255, 1)",
    fontSize: "16px",
    width: "100%", // Full width on small screens
    maxWidth: "150px", // Set a default max width
    borderRadius: "10px",
    textTransform: "none",
    padding: "12px",
    "@media (min-width: 600px)": {
      maxWidth: "300px", // Medium screens
    },
    "@media (min-width: 960px)": {
      maxWidth: "400px", // Larger screens
    },
    "@media (min-width: 1280px)": {
      maxWidth: "469px", // Extra large screens
    },
    "&:hover": {
      backgroundColor: "#007CD3", // Change background color to red on hover
    },
  }}
  variant="contained"
>
  Okay
</Button>
          </Stack>
        {/* </CenterItemBox> */}
      </Container>
  );
}

export default InviteSucessfull;

InviteSucessfull.propTypes = {
  onClose:PropTypes.func
}
