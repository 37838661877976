import React, { useState, useEffect, forwardRef } from "react";
import {
  Box,
  Typography,
  IconButton,
  Autocomplete,
  TextField,
  Avatar,
  Button,
  useTheme,
  Dialog,
  DialogTitle,
  DialogContent,
  InputLabel,
  DialogActions,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { fetchLanguageDropdown } from "../../../redux/slices/MeetingSlice";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import user from "../../../assets/images/videocall/girlonline.jpg";
import user2 from "../../../assets/images/videocall/men-img-a.jpg";
import user3 from "../../../assets/images/videocall/men-img-b.jpg";
import user4 from "../../../assets/images/videocall/girl-img-a.jpg";
import { PRIMARY } from "../../../theme/Palette";
import { CloseButton, OutlinedButton } from "../../../components/Butons/CustomButton";
import { CustomTypography4 } from "../../../components/Typography/Typography";
import {  ToastContainer, toast } from "react-toastify"; // Import toast components
import "react-toastify/dist/ReactToastify.css";
const participants = [
  {
    name: "Marvin Lambert",
    language: "English",
    insideMeeting: true,
    mic: true,
    avatar: user,
  }, //avatar for img for users
  {
    name: "Teresa Lloyd",
    language: "Spanish",
    insideMeeting: true,
    mic: false,
    avatar: user2,
  },
  {
    name: "Fred Haynes",
    language: "Spanish",
    insideMeeting: true,
    mic: true,
    avatar: user3,
  },
  {
    name: "Rose Peters",
    language: "Spanish",
    insideMeeting: true,
    mic: true,
    avatar: user4,
  },
  {
    name: "Marvin Lambert",
    language: "English",
    insideMeeting: false,
    avatar: user2,
  },
];

const Translation = forwardRef(({ quickCallId, setShowTranslation }) => {
  const dispatch = useDispatch();
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const { languageDropdown, isLoading } = useSelector((state) => state.meeting);
  const theme = useTheme();

  
  const [openConfirmModal, setOpenConfirmModal] = useState(false);

  // Function to open the modal
  const handleOpenConfirmModal = () => {
    setOpenConfirmModal(true);
  };

  // Function to close the modal
  const handleCloseConfirmModal = () => {
    setOpenConfirmModal(false);
  };


  const handleUpdate = () => {
    toast.success("updated sucessfully.",{
style:{
  fontWeight: 600,
  fontSize: "16px",
  lineHeight: "27px",
  fontFamily: "Lato",
  backgroundColor:"rgba(34, 37, 38, 1)",
  color:'rgba(255, 255, 255, 1)'
},
autoClose: 2000, 

    });
    
  };

  useEffect(() => {
    if (quickCallId) {
      dispatch(fetchLanguageDropdown({ meeting_id: quickCallId }))
        .then((res) => {
          const fetchedLanguages = res.payload;
          const originalLanguage = fetchedLanguages.find(
            (lang) => lang.name === "Original (Without Translation)"
          );
          if (originalLanguage) {
            setSelectedLanguage(originalLanguage); // Set original language as default
          }
        })
        .catch((err) => {
          console.error("Error fetching languages", err);
        });
    }
  }, [quickCallId, dispatch]);

  if (isLoading && !languageDropdown.length) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100%"
      >
        <Typography variant="body1">Loading...</Typography>
      </Box>
    );
  }

  const languageOptions = languageDropdown?.filter((lang) => lang.name) || [];

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        padding: "16px 16px 0",
        color: "#FFF",
        borderRadius: "8px",
        boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
        display: "flex",
        flexDirection: "column", justifyContent:"space-between", 
      }}
    >

<ToastContainer
        closeButton={false}
        toastStyle={{
          backgroundColor: PRIMARY.light,
          color: "#fff", // Custom text color
        }}
      />
      <Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between", // Places the IconButton on the right side
          width: "100%", // Make the container take full width
        }}
      >
        <Typography
          variant="h5"
          sx={{
            fontWeight: 600,
            fontSize: "18px",
            lineHeight: "27px",
            fontFamily: "Lato",
            color: "rgba(255, 255, 255, 0.9)",
          }}
        >
          Translation
        </Typography>
        <IconButton
          size="small"
          sx={{
            color: "white",
            border: "1px solid #444",
            borderRadius: "10px",
            width: "36px",
            height: "36px",
          }}
          onClick={() => setShowTranslation(false)}
        >
          <CloseIcon
            sx={{
              height: "18px",
              width: "18px",
              color: "rgba(255, 255, 255, 0.5)",
            }}
          />
        </IconButton>
      </Box>

      {/* Language Selection Dropdown */}
      <Box mt={3}>
        <Typography
          variant="body2"
          sx={{
            color: "rgba(255, 255, 255, 0.5)",
            fontSize: "14px",
            lineHeight: "21px",
            fontFamily: "Lato",
          }}
        >
          Your preferred language
        </Typography>
        <Autocomplete
          fullWidth
          options={languageOptions}
          getOptionLabel={(option) => option.name || ""}
          value={selectedLanguage}
          onChange={(event, newValue) => setSelectedLanguage(newValue)}
          clearIcon={null} // Hide the close icon
          popupIcon={
            <KeyboardArrowDownOutlinedIcon
              sx={{
                color: "rgba(255, 255, 255, 0.5)",
                width: "18px",
                height: "18px",
              }}
            />
          }
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Select Language"
              variant="standard"
              InputProps={{
                ...params.InputProps,
                style: {
                  paddingBlock: "10px",
                  fontSize: "14px",
                  fontFamily: "Lato",
                },
              }}
              sx={{
                "& .MuiInputBase-input::placeholder": {
                  fontSize: "15px", // Set placeholder font size
                  fontWeight: 500, // Set placeholder font weight
                  color: "rgba(255, 255, 255, 0.5)", // Set placeholder color
                },

                // Underline styles
                "& .MuiInput-underline:before": {
                  borderBottomColor: "rgba(245, 245, 250, 0.4)", // Default underline color
                },
                "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                  borderBottomColor: "rgba(245, 245, 250, 0.4)", // Underline color on hover
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: "rgba(245, 245, 250, 0.4)", // Underline color when focused
                },
                // Icon styles
                "& .MuiSvgIcon-root": {
                  color: "rgba(255, 255, 255, 0.5)", // Set icon color
                  width: "18px",
                  height: "18px",
                },
              }}
            />
          )}
        />
      </Box>

      <Box sx={{ marginTop: "30px" }}>
        <Typography
          variant="body2"
          sx={{
            color: "rgba(255, 255, 255, 0.5)",
            fontSize: "14px",
            lineHeight: "21px",
            fontFamily: "Lato",
            marginBottom: "10px",
          }}
        >
          Your preferred language
        </Typography>
        {participants
          .filter((p) => p.insideMeeting)
          .map((participant, index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                background: PRIMARY.darker,
                padding: "10px",
                marginBottom: "1px",
                marginX: "-15px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Avatar
                  sx={{
                    marginRight: 2,
                    height: "36px",
                    width: "36px",
                    borderRadius: "10px",
                  }}
                  variant="rounded"
                  src={participant.avatar}
                >
                  {participant.name.charAt(0)}
                </Avatar>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{
                      fontWeight: 700,
                      fontSize: "14px",
                      fontFamily: "Lato",
                    }}
                  >
                    {participant.name}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      fontWeight: 400,
                      fontSize: "14px",
                      fontFamily: "Lato",
                    }}
                    color="gray"
                  >
                    {participant.language}
                  </Typography>
                </Box>
              </Box>
            </Box>
          ))}
      </Box>
      </Box>
      <Box>
        <Box
          sx={{
            marginTop: "30px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            // backgroundColor: "#222526",
            backgroundColor: "#A1A1A11A",
            borderRadius: "4px",
            height: "48px",
            width: "100%",
            padding: "0 10px",
          }}
        >
          <Typography
            sx={{ fontWeight: 400, fontSize: "12px", fontFamily: "Lato" }}
          >
            10/50 hours Left                               
          </Typography>
          <Typography
            sx={{ fontWeight: 400, fontSize: "12px", fontFamily: "Lato" }}
          >
            Buy More
          </Typography>
        </Box>

        <Button
          variant="contained"
          sx={{
            textTransform: "none",
            width: "100%",
            borderRadius: "8px",
            marginTop: "10px",
            padding: "8px 40px",
            background: theme.palette.primary.info,
            color: theme.palette.primary.white,
            fontSize: "16px",
            fontFamily: "Lato",
            ":hover": {
              background: "#007CD3",
              color: theme.palette.primary.white,
            },
            
          }}
          onClick={handleUpdate}
        >
          Update
        </Button>
        <OutlinedButton
          sx={{
            px: 3,
            height: "46px",
            width: "100%",
            marginTop: "10px",
            backgroundColor: "#A1A1A11A",
            border: "none",
          }}
          onClick={handleOpenConfirmModal}
        >
          <CustomTypography4 sx={{ fontSize: "16px" }}>
            Disable Translation
          </CustomTypography4>
        </OutlinedButton>
        
      </Box>
      <Dialog open={openConfirmModal} onClose={handleCloseConfirmModal} maxWidth="sm" fullWidth>
        <Box p={2} bgcolor={"#222526"}>
          <DialogTitle>
            <Box sx={{          display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "20px",
                  alignItems: "center",}}>
           <CustomTypography4
                  sx={{
                    fontWeight: 700,
                    fontSize: "16px",
                    lineHeight: "24px",
                    color: "#FFFFFF",
                  }}
                ></CustomTypography4>

                  <CloseButton
                  onClick={handleCloseConfirmModal}
                  sx={{
                    width: "36px",
                    height: "36px",
                    border: "1px solid rgba(255, 255, 255, 0.5)", // Add border to close button
                  }}
                >
                  <CloseIcon
                    sx={{
                      color: "rgba(255, 255, 255, 0.5)",
                      width: "18px",
                      height: "18px",
                    }}
                  />
                </CloseButton>
            </Box>
          </DialogTitle>

          <DialogContent sx={{ textAlign: "center" }}>
              <Typography
                sx={{
                  color: "white",
                  fontSize: "26px",
                  lineHeight: "27px",
                  fontWeight: 700,
                  fontFamily: "Lato",
                  paddingBottom: "20px",
                }}
              >
               Are you sure
              </Typography>

              <InputLabel
                htmlFor="input-with-icon-textfield"
                sx={{
                  color: "rgba(128, 131, 163, 1)",
                  fontSize: "14px",
                  lineHeight: "21px",
                  fontWeight: 400,
                  fontFamily: "Lato",
                  paddingBottom: "20px",
                }}
              >
                Make sure your action is correct.
              </InputLabel>
            </DialogContent>
          <DialogActions>
          <Box
                mt={4}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
            <Button
              onClick={handleCloseConfirmModal}
              sx={{
                width: "213px",
                height: "52px",
                backgroundColor: "rgba(161, 161, 161, 0.1)",
                borderRadius: "10px",
                color: "rgba(255, 255, 255, 1)",
                fontWeight:700,
                fontSize:'16px',
                textTransform:'none',
                fontFamily:'Lato'
              }}
            >
              No
            </Button>

            <Button
              variant="contained"
              sx={{
                textTransform:'none',
                width: "213px",
                height: "52px",
                fontFamily:"Lato",
                fontWeight:700,
                fontSize:"16px",
                borderRadius: "10px",
                background: "#FF808B",
                marginLeft: "20px",
                color:"rgba(255, 255, 255, 1)",
                "&:hover": {
                  backgroundColor: "#e76b77",
                },
              }}
              onClick={handleCloseConfirmModal}
            >
              Yes, Close
            </Button>
            </Box>
          </DialogActions>
        </Box>
      </Dialog>
    </Box>
  );
});

Translation.displayName = "Translation";

Translation.propTypes = {
  quickCallId: PropTypes.string.isRequired,
  setShowTranslation: PropTypes.func.isRequired,
};

export default Translation;
