import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

export const CenterItemBox = styled(Box)(({theme}) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  minHeight: "100vh",
  background:theme.palette.background.lightGrey
}));
