import React, { useState, forwardRef } from "react";
import { Box, Typography, IconButton, TextField, Avatar ,InputAdornment} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import SendIcon from "@mui/icons-material/Send";
import ask from "../../assets/images/chat_bot/ask-ai.png";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import ThumbDownOffAltIcon from "@mui/icons-material/ThumbDownOffAlt";
import CachedIcon from "@mui/icons-material/Cached";
import Group from "../../assets/images/chat_bot/Group.png";


const ChatBot = forwardRef(({  setShowChatBot }) => {
    const [message, setMessage] = useState("");
  const [sentMessage, setSentMessage] = useState("");

  const handleSendMessage = () => {
    if (message.trim()) {
      setSentMessage(message); // Store the sent message
      setMessage(""); // Clear the input field after sending
    }
  };
  
  return (
    <Box
    sx={{
        width: "100%",
        height: "100%",
        padding: "16px 16px 0",
        color: "#FFF",
        borderRadius: "8px",
        boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
        display: "flex",
        flexDirection: "column", justifyContent: "space-between",
      }}
    >
      <Box>
        <Box display="flex" justifyContent="space-between" pt={3} px={1}>
          <Typography
            sx={{ fontFamily: "Lato", fontSize: "18px", fontWeight: 600 }}
            variant="h4"
          >
            <img src={Group} alt="group" style={{width:"20px", height:"20px", marginRight:"15px"}}/>
            Ask AI
          </Typography>
          <IconButton size="small"  onClick={() => setShowChatBot(false)}>
            <CloseIcon
              sx={{
                height: "24px",
                width: "24px",
                color: "#fff",
              }}
            />
          </IconButton>
        </Box>

        
        <Box
          mb={-1}
          sx={{ textAlign: "left", display: "flex", alignItems: "center" }}
          pb={3} pt={1} px={1}
        >
          <Avatar
            sx={{ width: "24px", height: "24px", marginRight: "10px" }}
          ></Avatar>
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: 400,
              fontFamily: "Lato", color:"#fff"
            }}
          >
            Give me more details about this
          </Typography>
        </Box>

        <Box
          mb={1}
          sx={{
            textAlign: "left",
            backgroundColor: "#000",
            borderRadius: "12px",
          }}
          py={3}
          pl={1}
          pr={2}
        >
          <Box sx={{ display: "flex", alignItems: "flex-start" }}>
            <img src={ask} alt="chat-ai" style={{height: "24px",
                width: "24px",}}/>
            <Typography
              sx={{
                fontSize: "14px",
                color: "#FFF",
                fontWeight: 400,
                fontFamily: "Lato",
              }}
            >
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt mollit anim id est laborum.
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  paddingTop: "20px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <IconButton
                    sx={{
                      backgroundColor: "#fff",
                      color: "#000",
                      borderRadius: "74px",
                      height: "26px",
                      width: "26px",
                      padding: "10px", marginRight:"10px"
                    }}
                  >
                    <ContentPasteIcon sx={{ width: "14px", height: "14px" }} />
                  </IconButton>
                  <IconButton  sx={{
                      backgroundColor: "#fff",
                      color: "#000",
                      borderRadius: "74px",
                      height: "26px",
                      width: "26px",
                      padding: "10px", marginRight:"10px"
                    }}>
                    <ThumbUpOffAltIcon sx={{ width: "14px", height: "14px" }}/>
                  </IconButton>
                  <IconButton  sx={{
                      backgroundColor: "#fff",
                      color: "#000",
                      borderRadius: "74px",
                      height: "26px",
                      width: "26px",
                      padding: "10px", marginRight:"10px"
                    }}>
                    <ThumbDownOffAltIcon sx={{ width: "14px", height: "14px" }}/>
                  </IconButton>
                </Box>
                <Box>
                  <IconButton
                    sx={{ backgroundColor: "#fff", color: "#0092F8" , borderRadius: "74px",
                      height: "26px",
                      width: "26px",
                      padding: "10px",}}
                  >
                    <CachedIcon sx={{ width: "14px", height: "14px" }}/>
                  </IconButton>
                </Box>
              </Box>
            </Typography>
          </Box>
        </Box>

        {sentMessage && (
          <Box
            mb={-1}
            sx={{ textAlign: "left", display: "flex", alignItems: "center" }}
            pb={3}
            pt={1}
            px={1}
          >
            <Avatar
              sx={{ width: "24px", height: "24px", marginRight: "10px" }}
            ></Avatar>
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: 400,
                fontFamily: "Lato",
                color: "#fff",
              }}
            >
              {sentMessage}
            </Typography>
          </Box>
        )}

      </Box>
      <Box>
        <Box
          sx={{
            marginTop: "30px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            height: "48px",
            width: "100%",
            padding: "0 10px",
          }}
        >
          <Typography
            sx={{ fontWeight: 400, fontSize: "13px", fontFamily: "Lato", backgroundColor:"#FFFFFF26", borderRadius:"16px" , padding:"10px 15px"}}
          >
            Give me a summary
          </Typography>
          <Typography
            sx={{ fontWeight: 400, fontSize: "13px", fontFamily: "Lato" ,backgroundColor:"#FFFFFF26", borderRadius:"16px" , padding:"10px 15px"}}
          >
            Explain
          </Typography>
        </Box>
        <Box>
        <Box sx={{ position: "relative", display: "flex", alignItems: "center", mt: 2 }}>
        <TextField
          variant="outlined"
          fullWidth
          multiline
          maxRows={2}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "24px",
                    height: "24px",
                    backgroundColor: "#333",
                    borderRadius: "4px",
                    color: "#FFFFFF80",
                    fontSize: "14px",
                    marginRight: "8px",
                    border: "1px solid #555",
                  }}
                >
                  /
                </Box>
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  color="primary"
                  onClick={handleSendMessage}
                  sx={{
                    color: "#FFFFFF80",
                    "&:hover": {
                      color: "white",
                    },
                    padding: "10px 10px 0",
                  }}
                >
                  <SendIcon sx={{ height: "18px", width: "18px" }} />
                </IconButton>
              </InputAdornment>
            ),
            sx: {
              height:"75px",
              fontFamily: "Lato",
              fontSize: "16px",
              color: "white",
              borderRadius: "5px",
              padding: "8px 12px 0px",
              border: "1px solid #FFFFFF80", // Adds border around the TextField
              "& .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
            },
          }}
        />

        {/* Send Icon */}
        {/* <IconButton
          color="primary"
          onClick={handleSendMessage}
          sx={{
            position: "absolute",
            right: "10px",
            bottom:"0",
            color: "#FFFFFF80",
            "&:hover": {
              color: "white",
            },
            padding: "10px",
          }}
        >
          <SendIcon sx={{ height: "18px", width: "18px" }} />
        </IconButton> */}
      </Box>
        </Box>
      </Box>
    </Box>
  );
});

ChatBot.displayName = "ChatBot";

ChatBot.propTypes = {
  quickCallId: PropTypes.string.isRequired,
  setShowChatBot: PropTypes.func.isRequired,
};

export default ChatBot;
