import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Stack } from "@mui/material";
// import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";


export default function BasicModal({ open, setOpen, children,style }) {
  const handleClose = () => setOpen(false);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        {/* <Stack
          sx={{ display: "flex", justifyContent: "end", alignItems: "end" }}
        >
          <CancelPresentationIcon
            onClick={handleClose}
            sx={{
              color: "var(--8083-a-3, #8083A3)",
              width: "40px",
              height: "40px",
            }}
          />
        </Stack> */}
        <Stack spacing={2}>{children}</Stack>
      </Box>
    </Modal>
  );
}

BasicModal.propTypes = {
  open: PropTypes.bool,
  style:PropTypes.object,
  setOpen: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};
