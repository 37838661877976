import React from "react";
import { useRoutes, Navigate } from "react-router-dom";
import AuthGuard from "../pages/auth/AuthGuard";
import DashboardLayout from "../layout/DashboardLayout";
import AdminDashboardLayout from "../layout/AdminDashboardLayout";
import SimpleLayout from "../layout/SimpleLayout";
import BusinessDasboardlayout from "../pages/businessUser/BussinesLayout/BusinessDasboardLayout"
import {
  SignUpPage,
  LoginPage,
  ResetPasswordPage,
  DashBoardPage,
  MessagePage,
  ForgetPasswordPage,
  OtpVerificationPage,
  PromoCodePage,
  EmailStatusPage,
  ForgetPasswordResetSuccess,
  ForgetPasswordEmailPage,
  SettingsPage,
  VideoCallPage,
  SchedulePage,
  MeetingJoinPage,
  ParticipantsPage,
  SearchPage,
  FaqPage,
  DocumentsPage,
  ContactPage,
  NotificationPage,
  GuestLoginPage,
  MeetingNotStartPage,
  GuestVideoCallApp,
  CreateMeetingPage,
  CallHistoryPage,
  SocialMediaSignUpSuccessPage,
  ManageSubscriptionPage, 
  ChangePasswordPage,
  EditProfilePage, OrderHistorySection,NotificationSection,SubscriptionCard,
  BusinessDashBoardPage,
  BusinessNotification,
  BusinessCalender,
  BusinessDocumentPage,
  BussinessMessenger,
  BusinessContacts,
  BusinessSettings,
  BusinessEditprofile,
  ManagePlanIndex,
  BusinessChangePasswordForm,
  BusinessNotificationSection,
 // BusinessFaq,
 BusinesssFAQ,
  BusinessIndex,
  BusinessMeetingjoiningPge,
  BusinessLoginPage,
  BusinessSignUpPage,
  AdminNotificationPage,
  AdminDashBoardPage,
  AdminCustomersPage,
  AdminPlansPage,
  AdminReportsPage, 
  AdminUsersPage,
  BusinessOtpVerificationPage,
  AdminBillingPage,
  AdminSupportPage,
  AdminPromotionPage,
  AdminSettingsPage,
  AdminLoginPage,
  BusinessResetPasswordPage,
  BusinessEmailStatusPage,
  BusinessSearchPage,
  BusinessGuestLoginPage,
  BusinessGuestVideoCallApp,
  
} from "./elements";
import { PATH_AFTER_LOGIN } from "../utils/Config-globals";

import BusinessVideoCallApp from "../pages/businessUser/Calls/BusinessVideoCallScreen";
import BusinessForgetPassword from "../pages/businessUser/Auth/BusinessForgotPassword";
import BusinessForgetPasswordResetSuccess from "../pages/businessUser/Auth/BusinessPasswordResetSucess";
import BusinessResetPassword from "../pages/businessUser/Auth/BusinessPasssWordReset";



export default function Router() {
  return useRoutes([
    { path: "/", element: <Navigate to="/auth/login" /> },
    {
      path: "auth",
      children: [
        { path: "login", element: <LoginPage /> },
        { path: "sign-up", element: <SignUpPage /> },
        { path: "reset-password", element: <ResetPasswordPage /> },
        { path: "otp-verification", element: <OtpVerificationPage /> },
        { path: "forgot-password", element: <ForgetPasswordPage /> },
        { path: "promocode", element: <PromoCodePage /> },
        { path: "email-sucess", element: <EmailStatusPage /> },
        { path: "password-sucess", element: <ForgetPasswordEmailPage /> },
        { path: "password-reset-success", element: <ForgetPasswordResetSuccess /> },
        { path: "guest-login", element: <GuestLoginPage /> }, // Existing route for guest login
        { 
          path: "guest-login/join", 
          element: <GuestLoginPage />  // <-- New route for guest login with join
        },
        { path: "create-meeting/:id", element: <CreateMeetingPage /> },
        { path: "social-media-signup", element: <SocialMediaSignUpSuccessPage /> },
      ],
    },
    {
      path: "dashboard",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: "Overview", element: <DashBoardPage /> },
        { path: "messages", element: <MessagePage /> },
        { path: "settings", element: <SettingsPage /> },
        { path: "schedule", element: <SchedulePage /> },
        { path: "subscription", element: <SubscriptionCard /> },
        { path: "contacts", element: <ContactPage /> },
        { path: "notifications", element: <NotificationPage /> },
        { path: "meeting-join", element: <MeetingJoinPage /> },
        { path: "participants", element: <ParticipantsPage /> },
        { path: "support", element: <FaqPage /> },
        { path: "documents", element: <DocumentsPage /> },
        { path: "Search", element: <SearchPage /> },
        {
          path: "settings",
          element: <SettingsPage />,
          children: [
            { path: "manage-subscription", element: <ManageSubscriptionPage /> },  // Add child routes for sub-pages
            { path: "edit-profile", element: <EditProfilePage /> },
            { path: "change-password", element: <ChangePasswordPage /> }, 
            { path: "order-history", element: <OrderHistorySection /> }, 
            { path: "notification", element: <NotificationSection /> },
          ],
        },
        {
          path: "call",
          children: [
            { element: <CallHistoryPage />, index: true },
            { path: "meet", element: <VideoCallPage /> },
            {
              path: "join/:meetingId",
              element: <MeetingJoinPage />,
            },
            {
              path: "join/",
              element: <MeetingJoinPage />, // Render the MeetingJoinPage directly
            },
          ],
        },
      ],
    },
    {
      path: "guest",
      element: <SimpleLayout />, // Layout for guest pages
      children: [
        { path: "join/:quickCallId", element: <MeetingJoinPage /> }, 
        { path: "meet", element: <GuestVideoCallApp /> },
        { path: "not-start", element: <MeetingNotStartPage /> }, 
      ],
    },  


//for business user
{ path: "/", element: <Navigate to="business/auth/login" /> },
{
  path: "businessauth",
  children: [
    { path: "login", element: <BusinessLoginPage /> },
    { path: "signup", element: <BusinessSignUpPage /> },
    { path: "email-sucess", element: <BusinessEmailStatusPage /> },
    { path: "otp-verification", element: <BusinessOtpVerificationPage /> },

    { path: "reset-password", element: <BusinessResetPasswordPage /> },
    { path: "forgot-password", element: <BusinessForgetPassword /> },
    { path: "promocode", element: <PromoCodePage /> },

    { path: "password-sucess", element: <ForgetPasswordEmailPage /> },
    { path: "password-reset-success", element: <BusinessForgetPasswordResetSuccess /> },
    { path: "newpassword-reset-form", element: <BusinessResetPassword /> },

    { path: "guest-login", element: <BusinessGuestLoginPage /> }, // Existing route for guest login
    { 
      path: "guest-login/join", 
      element: <BusinessGuestLoginPage />  // <-- New route for guest login with join
    },
    { path: "create-meeting/:id", element: <CreateMeetingPage /> },
    { path: "social-media-signup", element: <SocialMediaSignUpSuccessPage /> },
  ],
},
    {
      path: "business",
  
      element:
         <BusinessDasboardlayout />, 
      children: [
        { path: "overview", element: <BusinessDashBoardPage /> },
        //{ path: "meet", element: <GuestVideoCallApp /> },
        { path: "not-start", element: <MeetingNotStartPage /> }, 
        { path: "notifications", element: <BusinessNotification />  }, 
        { path: "schedule", element: <BusinessCalender />  }, 
        { path: "documents", element: <BusinessDocumentPage />  }, 
        { path: "messages", element: <BussinessMessenger /> },
        { path: "contacts", element: <BusinessContacts /> },
       { path: "settings", element: <BusinessSettings /> },
       { path: "support", element: <BusinesssFAQ /> },
       { path: "Search", element: <BusinessSearchPage/> },

        {
          path: "settings",
          element: <BusinessSettings />,
          children: [
            { path: "edit-profile", element: <BusinessEditprofile /> },
            { path: "manage-plan", element: <ManagePlanIndex /> },  
            { path: "change-password-business", element: <BusinessChangePasswordForm /> }, 
            { path: "notification-business", element: <BusinessNotificationSection /> },
          ],
        }
        ,
        {
          path: "call",
          children: [
            { element: <BusinessIndex />, index: true },
            { path: "meet", element: <BusinessVideoCallApp /> },
            {
              path: "join/:meetingId",
              element: <BusinessMeetingjoiningPge />,
            },
             {
               path: "join/",
               element: <BusinessMeetingjoiningPge />, // Render the MeetingJoinPage directly
             },
          ],
        },
      ]
    }, 

    {
      path: "guest",
      element: <SimpleLayout />, // Layout for guest pages
      children: [
        { path: "join/:quickCallId", element: <BusinessMeetingjoiningPge /> }, 
        { path: "meet", element: <BusinessGuestVideoCallApp /> },
        { path: "not-start", element: <MeetingNotStartPage /> }, 
      ],
    },  




    //FOR ADMIN
    { path: "/", element: <Navigate to="/auth/admin-login" /> },
    {
      path: "auth",
      
      children: [
        { path: "admin-login", element: <AdminLoginPage /> },
      ],
    },
    {
      path: "admin",
      element: (
        <AuthGuard>
          <AdminDashboardLayout/>
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: "overview", element: <AdminDashBoardPage /> },
        { path: "notifications", element: <AdminNotificationPage /> },
        { path: "customers", element: <AdminCustomersPage /> }, 
        { path: "plan", element: <AdminPlansPage /> },  
        { path: "reports", element: <AdminReportsPage /> }, 
        { path: "user", element: <AdminUsersPage /> }, 
        { path: "billing", element: <AdminBillingPage /> }, 
        { path: "support", element: <AdminSupportPage /> },  
        { path: "promotion", element: <AdminPromotionPage /> },  
        { path: "settings", element: <AdminSettingsPage /> },  
        
        // { path: "messages", element: <MessagePage /> },
        // { path: "settings", element: <SettingsPage /> },
        // { path: "schedule", element: <SchedulePage /> },
        // { path: "subscription", element: <SubscriptionCard /> },
        // { path: "contacts", element: <ContactPage /> },
        // { path: "meeting-join", element: <MeetingJoinPage /> },
        // { path: "participants", element: <ParticipantsPage /> },
        // { path: "support", element: <FaqPage /> },
        // { path: "documents", element: <DocumentsPage /> },
        // {
        //   path: "settings",
        //   element: <SettingsPage />,
        //   children: [
        //     { path: "manage-subscription", element: <ManageSubscriptionPage /> },  // Add child routes for sub-pages
        //     { path: "edit-profile", element: <EditProfilePage /> },
        //     { path: "change-password", element: <ChangePasswordPage /> }, 
        //     { path: "order-history", element: <OrderHistorySection /> }, 
        //     { path: "notification", element: <NotificationSection /> },
        //   ],
        // },
      ],
    }, 
  ]);
}
