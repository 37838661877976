import React, {
    useState,
    useEffect,
    useImperativeHandle,
    forwardRef,
  } from "react";
  import {
    Box,
    Typography,
    IconButton,
    Autocomplete,
    TextField,
    Dialog,
    DialogTitle,
  } from "@mui/material";
  import SettingsIcon from "@mui/icons-material/Settings";
  import CloseIcon from "@mui/icons-material/Close";
  import { useDispatch, useSelector } from "react-redux";
  import {
    fetchLanguageDropdown,
    fetchCaptions,
  } from "../../../redux/slices/MeetingSlice";
  import PropTypes from "prop-types";
  import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
  
  // Use forwardRef to expose the triggerCaptionsAPI method
  const Captions = forwardRef(({ isOpen, quickCallId, setShowCaptions }, ref) => {
    const dispatch = useDispatch();
    const [selectedLanguage, setSelectedLanguage] = useState("");
    const [captionsEntries, setCaptionsEntries] = useState([]);
    const { languageDropdown } = useSelector((state) => state.meeting);
    const [fontSize, setFontSize] = useState(16); // State for managing caption font size
    const [openModal, setOpenModal] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null); // Reference for the settings icon element
  
    useEffect(() => {
      if (quickCallId) {
        dispatch(fetchLanguageDropdown({ meeting_id: quickCallId }))
          .then((res) => {
            const fetchedLanguages = res.payload;
            const originalLanguage = fetchedLanguages.find(
              (lang) => lang.name === "Original (Without Translation)"
            );
            if (originalLanguage) {
              setSelectedLanguage(originalLanguage);
            }
          })
          .catch((err) => {
            console.error("Error fetching languages", err);
          });
      }
    }, [quickCallId, dispatch]);
  
    useEffect(() => {
      if (quickCallId && selectedLanguage) {
        dispatch(
          fetchCaptions({
            quick_call_id: quickCallId,
            language: selectedLanguage.name,
          })
        )
          .then((res) => {
            const captionsArray =
              res.payload?.participants.map((participant) => ({
                name: participant.participant_name,
                text: participant.translated_text,
              })) || [];
            setCaptionsEntries(captionsArray);
          })
          .catch((err) => {
            console.error("Error fetching captions:", err);
          });
      }
    }, [quickCallId, selectedLanguage, dispatch]);
  
    useImperativeHandle(ref, () => ({
      // triggerCaptionsAPI,
    }));
  
    const handleFontSizeIncrease = () => {
      setFontSize((prevSize) => prevSize + 1);
    };
  
    const handleFontSizeDecrease = () => {
      setFontSize((prevSize) => (prevSize > 10 ? prevSize - 1 : prevSize));
    };
  
    const handleSettingsClick = (event) => {
      setAnchorEl(event.currentTarget); // Set the reference of the clicked element
      setOpenModal(!openModal); // Toggle modal visibility
    };
  
    const handleClose = () => {
      setOpenModal(false); // Close the modal
      setAnchorEl(null); // Reset the anchor element
    };
  
    const positionStyle = anchorEl
      ? {
          top: `${anchorEl.getBoundingClientRect().top - 220}px`, // Position above the settings icon
          left: `${anchorEl.getBoundingClientRect().left - 150}px`, // Align left to the settings icon
          position: "absolute",
        }
      : {}; // Empty style if the anchorEl is null
  
    if (!isOpen) return null;
  
    return (
      <Box
        sx={{
          position: "absolute",
          bottom: "0",
          left: "0%",
          width: "100%",
          backgroundColor: "rgba(34, 37, 38, 1)",
          color: "white",
          padding: "12px 34px",
          fontFamily: "'Roboto', sans-serif",
          borderTop: "1px solid #333",
          display: "block",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {/* Right-side: Language selection, settings, and close icons */}
        <Box display="flex" alignItems="center" justifyContent="flex-end" gap={2}>
          <Typography
            variant="body2"
            sx={{ color: "#cfcfcf", marginRight: "8px", fontFamily: "Lato",fontWeight:400 ,fontSize:"14px" }}
          >
            Change Language
          </Typography>
  
          <Autocomplete
           
  disableClearable // Disables the close (clear) icon
              sx={{
              width: "15%",
              border: "1px solid rgba(255, 255, 255, 0.15)",
              p:1,
              borderRadius: "8px",
              height: "36px",
                 display:"flex",
               alignItems: "center"
            }}
            options={languageDropdown?.filter((lang) => lang.name) || []}
            getOptionLabel={(option) => option.name || ""}
            value={selectedLanguage}
            onChange={(event, newValue) => setSelectedLanguage(newValue)}
  
            popupIcon={<KeyboardArrowDownIcon sx={{height:"18px",width:"18px"}} />} 
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                
                placeholder="Select Language"
                InputProps={{
                  ...params.InputProps,
                  disableUnderline: true, // Disable the default underline
                  style: { border: "none",padding: '0 10px'  }, // Remove any existing borders
                }}
                inputProps={{
                  ...params.inputProps,
                  style: { fontSize: '14px', fontFamily:"Lato" }, // Placeholder font size and weight
                }}
                sx={{
                  "& .MuiInput-root": {
                    display: 'flex', 
                    alignItems: 'center', // Vertically aligns the content inside the input
                    height: "100%", // Ensures the input field takes full height
                  },
                  "& .MuiInput-root:before": {
                    borderBottom: "none", // Remove the before pseudo-element border
                  },
                  "& .MuiInput-root:hover:not(.Mui-disabled):before": {
                    borderBottom: "none", // Remove border on hover
                  },
                  "& .MuiInput-root:after": {
                    borderBottom: "none", // Remove the after pseudo-element border
                  },
                }}
              />
            )}
          />
  
          <IconButton
            sx={{
              color: "white",
              border: "1px solid rgba(255, 255, 255, 0.15)",
              borderRadius: "10px",
              width: "36px",
              height: "36px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              
            }}
            onClick={handleSettingsClick}
          >
            <SettingsIcon  sx={{height:"18px",width:"18px",color:"rgba(255, 255, 255, 0.5)"}}/>
          </IconButton>
  
          <IconButton
            sx={{
            
              border: "1px solid rgba(255, 255, 255, 0.15)",
              borderRadius: "10px",
              width: "36px",
              height: "36px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "#FFFFFF80",
            }}
            onClick={() => setShowCaptions(false)}
          >
            <CloseIcon  sx={{height:"18px",width:"18px",color:"rgba(255, 255, 255, 0.5)"}}/>
          </IconButton>
        </Box>
  
        {/* Left-side: Captions Text */}
        <Box
          display="flex"
          flexWrap="wrap"
          mt={2}
          flexDirection="column"
          sx={{ maxHeight: "60px" }}
        >
          {captionsEntries.length > 0 ? (
            captionsEntries.map((caption, index) => (
              <Box display="flex" alignItems="center" key={index} mb={1}>
                <Typography
                  variant="body2"
                  sx={{
                    color: "#cfcfcf",
                    marginRight: "8px",
                    fontSize: `${fontSize}px`,
                    fontFamily: "Lato",
                  }}
                >
                  <strong>{caption.name}:</strong>
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    color: "#ffffff",
                    fontSize: `${fontSize}px`,
                    fontFamily: "Lato",
                  }}
                >
                  <strong>{caption.text}</strong>
                </Typography>
              </Box>
            ))
          ) : (
            <Typography variant="body2" sx={{ color: "#ffffff", fontSize: "18px",fontFamily:"Lato",fontWeight:600 }}>
              No captions available.
            </Typography>
          )}
        </Box>
  
        <Dialog
          open={openModal}
          onClose={handleClose}
          PaperProps={{
            style: {
              ...positionStyle, // Apply the dynamic positioning
              backgroundColor: "#222526",
              borderRadius: "8px",
              overflow: "visible",
              margin: 0,
            },
          }}
          BackdropProps={{ invisible: true }} // Make backdrop invisible for dropdown-like behavior
        >
          {/* Arrow element */}
          <Box
            sx={{
              position: "absolute",
              bottom: "-10px", // Position at the bottom of the modal
              left: "50%",
              transform: "translateX(-50%)",
              width: "0",
              height: "0",
              borderLeft: "10px solid transparent",
              borderRight: "10px solid transparent",
              borderTop: "10px solid #222526", // Triangle pointing down
            }}
          />
          <Box
            p={2}
            bgcolor={"#222526"}
            sx={{ width: "250px", borderRadius: "8px" }}
          >
            <DialogTitle
              sx={{
                padding: 0,
                color: "#fff",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: 700,
                  fontFamily: "Lato",
                }}
              >
                Caption size
              </Typography>
              <Box
                sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
              >
                <Typography
                  onClick={() => setFontSize(16)}
                  sx={{
                    color: "#FF808B",
                    mr: 2,
                    textTransform: "capitalize",
                    fontSize: "16px",
                    fontWeight: 700,
                    fontFamily: "Lato",
                  }}
                >
                  Reset
                </Typography>
                <IconButton
                  sx={{
                    border: "1px solid #FFFFFF80",
                    borderRadius: "8px",
                    width: "36px",
                    height: "36px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "#FFFFFF80",
                  }}
                  onClick={handleClose}
                >
                  <CloseIcon />
                </IconButton>
              </Box>
            </DialogTitle>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              my={4}
              sx={{ backgroundColor: "#1A1C1D", height: "70px", padding: "0 15px" }}
            >
              <IconButton
                sx={{
                  border: "1px solid #FFFFFF80",
                  borderRadius: "8px",
                  width: "36px",
                  height: "36px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  color: "#FFFFFF80",
                }}
                onClick={handleFontSizeDecrease}
              >
                -
              </IconButton>
              <Typography
                sx={{
                  color: "#fff",
                  fontSize: "16px",
                  fontWeight: 700,
                  fontFamily: "Lato",
                }}
              >
                {fontSize}px
              </Typography>
              <IconButton
                sx={{
                  border: "1px solid #FFFFFF80",
                  borderRadius: "8px",
                  width: "36px",
                  height: "36px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  color: "#FFFFFF80",
                }}
                onClick={handleFontSizeIncrease}
              >
                +
              </IconButton>
            </Box>
          </Box>
        </Dialog>
      </Box>
    );
  });
  
  Captions.displayName = "Captions";
  
  Captions.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    quickCallId: PropTypes.string.isRequired,
    setShowCaptions: PropTypes.func.isRequired,
  };
  
  export default Captions;
  